/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.invoice-financing-contractor-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .invoice-financing-contractor-container img#sideBanner {
    -webkit-box-flex: 2;
        -ms-flex: 2 1;
            flex: 2 1;
    -o-object-fit: cover;
       object-fit: cover; }
    @media (max-width: 1024px) {
      .invoice-financing-contractor-container img#sideBanner {
        display: none; } }

.invoice-financing-contractor-registration-step {
  -webkit-box-flex: 2;
      -ms-flex: 2 1;
          flex: 2 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px 20px;
  min-width: 544px;
  width: 100%; }
  .invoice-financing-contractor-registration-step.hidden {
    display: none; }
  .invoice-financing-contractor-registration-step .registration-step--header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 50px;
    width: 100%; }
  .invoice-financing-contractor-registration-step .registration-step--body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%; }
    .invoice-financing-contractor-registration-step .registration-step--body .feature-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 15px; }
      .invoice-financing-contractor-registration-step .registration-step--body .feature-item .item-icon {
        width: 50px;
        height: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        background-color: #eaf2f4;
        color: #2c3739; }
      .invoice-financing-contractor-registration-step .registration-step--body .feature-item .text-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-left: 15px;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        width: 100%; }
        .invoice-financing-contractor-registration-step .registration-step--body .feature-item .text-content span {
          color: #7e8281; }

.invoice-financing-contractor-registration-step:not(:first-of-type) {
  padding-top: 0; }

.registration-step--footer {
  padding: 0 20px 20px 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.invoice-financing-contractor-registration-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%; }

.progressbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 30px; }

.progressbar::before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 2px;
  width: 100%;
  background-color: #dfe3e4;
  counter-reset: step; }

.progress-step {
  position: relative;
  width: 35px;
  width: 2.1875rem;
  height: 35px;
  height: 2.1875rem;
  background-color: #dfe3e4;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.progress-step::before {
  counter-increment: step;
  content: counter(step); }

.progress-step::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% - 0.01rem);
  font-size: 13.6px;
  font-size: 0.85rem;
  color: #B7B7B7; }

.progress-step--active {
  background-color: #286481;
  color: #fff; }

.progress-step--active::after {
  color: #353535; }

.invoice-funding-form {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 50px 20px;
  margin-left: auto;
  margin-right: auto; }

.invoice-funding-form-step {
  border: 1px solid #C0C7CD;
  padding: 28px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 28px;
  margin-bottom: 28px;
  min-width: 544px; }
  .invoice-funding-form-step h2 {
    margin-bottom: 28px; }
  .invoice-funding-form-step .form-block-title {
    font-size: 12px;
    font-weight: 500;
    color: #71787D;
    margin-bottom: 12px; }
    .invoice-funding-form-step .form-block-title.input-list-title {
      margin-bottom: 0; }
  .invoice-funding-form-step .input-list-title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .invoice-funding-form-step .invoice-list-form-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .invoice-funding-form-step .form-block {
    background-color: rgba(0, 102, 136, 0.07843);
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-radius: 2px;
    margin-bottom: 28px; }
    .invoice-funding-form-step .form-block .form-row {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      font-size: 14px;
      margin-bottom: 8px; }
      .invoice-funding-form-step .form-block .form-row .form-col-1 {
        color: #71787D;
        font-weight: 500; }
      .invoice-funding-form-step .form-block .form-row .form-col-2 {
        color: #191C1E;
        font-weight: 400; }
      .invoice-funding-form-step .form-block .form-row.total-row {
        margin-top: 16px; }
        .invoice-funding-form-step .form-block .form-row.total-row .total-col {
          font-size: 16px;
          font-weight: 600; }
  .invoice-funding-form-step .info-line-1 {
    margin-top: 20px; }
  .invoice-funding-form-step .info-line-2 {
    margin-top: 8px; }
  .invoice-funding-form-step .info-line-1, .invoice-funding-form-step .info-line-2 {
    display: inline-block;
    font-size: 12px;
    font-weight: 400; }
  .invoice-funding-form-step .next-button {
    -ms-flex-item-align: end;
        align-self: flex-end;
    background-color: #006688;
    border: 1px solid #006688;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 24px; }
    .invoice-funding-form-step .next-button span {
      display: inline-block;
      margin-right: 12px; }
    .invoice-funding-form-step .next-button:disabled {
      background-color: rgba(0, 102, 136, .12);
      color: rgba(0, 102, 136, 0.58824);
      border-color: transparent;
      cursor: inherit; }

.form-input-group {
  margin-bottom: 15px; }
  .form-input-group label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #40484C; }
  .form-input-group input {
    background-color: rgba(0, 102, 136, .08);
    border-radius: 2px 2px 0 0;
    border: 1px solid transparent;
    border-bottom-color: #C5C6C8; }
    .form-input-group input:focus {
      border: 1px solid #006688; }

.invoice-funding-status {
  border-radius: 4px;
  background-color: #F5BF65;
  padding: 7px;
  text-align: center;
  font-size: 19.2px;
  font-size: 1.2rem;
  margin-bottom: 15px;
  text-transform: uppercase; }
  .invoice-funding-status.invoice-funding-status--requested {
    background-color: #F5BF65; }
  .invoice-funding-status.invoice-funding-status--approved {
    background-color: #4FCBB2; }
  .invoice-funding-status.invoice-funding-status--paid {
    background-color: #4FCBB2; }

.form__group input.form__control.form__control--blue:disabled {
  background-color: #E0E0E0;
  border: 1px solid #E0E0E0; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
body {
  background: #F4F4F4; }

.building-layout-wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto; }

.building-profile-dropdown {
  background-color: #000000;
  padding: 0 30px 15px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (min-width: 660px) {
    .building-profile-dropdown {
      display: none; } }
  @media (max-width: 499px) {
    .building-profile-dropdown {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  @media (max-width: 499px) {
    .building-profile-dropdown {
      padding: 0 20px 15px 20px; } }

.building-profile-wrapper {
  line-height: 1em; }
  @media (max-width: 768px) {
    .building-profile-wrapper {
      display: grid; } }
  @media (max-width: 768px) {
    .building-profile-wrapper.building-profile-wrapper_redesign {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }
  .building-profile-wrapper .building-profile-component {
    margin: auto;
    max-width: 1400px;
    padding: 20px 30px; }
    .building-profile-wrapper .building-profile-component .alice-carousel__wrapper {
      padding: 5px 0;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, .1); }
    .building-profile-wrapper .building-profile-component:last-child {
      padding-bottom: 30px; }
    @media (max-width: 1024px) {
      .building-profile-wrapper .building-profile-component {
        margin: unset;
        padding: 15px 30px; } }
    @media (max-width: 768px) {
      .building-profile-wrapper .building-profile-component {
        padding: 15px 10px; } }
    @media (max-width: 768px) {
      .building-profile-wrapper .building-profile-component {
        padding: 15px 30px; } }
    @media (max-width: 499px) {
      .building-profile-wrapper .building-profile-component {
        padding: 15px; } }
  .building-profile-wrapper .building-title-margin {
    margin: 0 0 30px 0; }
  @media (max-width: 1024px) {
    .building-profile-wrapper .building-title-heading {
      font-size: 18px; } }
  @media (max-width: 768px) {
    .building-profile-wrapper .building-title-heading {
      font-size: 16px; } }
  @media (max-width: 499px) {
    .building-profile-wrapper .building-title-heading {
      font-size: 18px; } }
  .building-profile-wrapper .subtitle {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer; }
    @media (max-width: 768px) {
      .building-profile-wrapper .subtitle {
        font-size: 15px; } }
  .building-profile-wrapper .building-profile-button {
    line-height: 1em;
    text-transform: none; }
    @media (max-width: 1024px) {
      .building-profile-wrapper .building-profile-button {
        padding: 0 10px;
        min-width: 100px; } }
    @media (max-width: 768px) {
      .building-profile-wrapper .building-profile-button {
        padding: 0; } }
    @media (max-width: 399px) {
      .building-profile-wrapper .building-profile-button {
        min-width: 95px;
        margin-left: 10px; } }
    @media (max-width: 339px) {
      .building-profile-wrapper .building-profile-button {
        font-size: 12px;
        min-width: 85px; } }
  .building-profile-wrapper .right-grid-popular-documents {
    padding: 20px 30px 20px 20px; }
    @media (max-width: 1024px) {
      .building-profile-wrapper .right-grid-popular-documents {
        padding: 10px 15px 10px 10px; } }
    @media (max-width: 768px) {
      .building-profile-wrapper .right-grid-popular-documents {
        padding: 15px 30px; } }
    @media (max-width: 499px) {
      .building-profile-wrapper .right-grid-popular-documents {
        padding: 15px; } }
  .building-profile-wrapper .left-grid-work-history {
    padding: 20px 20px 20px 30px; }
    @media (max-width: 1024px) {
      .building-profile-wrapper .left-grid-work-history {
        padding: 10px 10px 10px 15px; } }
    @media (max-width: 768px) {
      .building-profile-wrapper .left-grid-work-history {
        padding: 15px 30px; } }
    @media (max-width: 499px) {
      .building-profile-wrapper .left-grid-work-history {
        padding: 15px; } }
  .building-profile-wrapper .grid-wrapper {
    display: grid;
    grid-template-columns: 50% 50%; }
    @media (max-width: 768px) {
      .building-profile-wrapper .grid-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .building-profile-wrapper .grid-wrapper .col-span-two {
      grid-column: 1/ span 2; }
  @media (max-width: 659px) {
    .building-profile-wrapper .work-documents-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse;
      padding-bottom: 0; } }
  .building-profile-wrapper .industry-news-component {
    background-color: #f2f2f2; }
  .building-profile-wrapper .mobile-view-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.building-profile-map {
  height: 400px; }

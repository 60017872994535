/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.message-textarea input[type=text]:focus, .message-textarea textarea:focus {
  outline: none; }

.write-message-container .preview-current-files {
  padding-top: 12px; }
  .write-message-container .preview-current-files .preview-current-file {
    display: inline-block;
    position: relative;
    padding: 0 12px 12px 12px; }
    .write-message-container .preview-current-files .preview-current-file .preview-attachment-remove-btn {
      position: absolute;
      top: -5px;
      right: 2px;
      cursor: pointer; }

.write-message-container .message-box-action-buttons-wrapper {
  margin-top: 5px;
  float: right; }
  .write-message-container .message-box-action-buttons-wrapper button.message-send-button {
    padding: 5px 8.5px; }
  .write-message-container .message-box-action-buttons-wrapper button.message-box-attach-file {
    margin-right: 5px;
    padding: 5px 10px; }
  .write-message-container .message-box-action-buttons-wrapper button:disabled {
    background: #333333;
    border-color: #333333;
    cursor: default; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.gooddata-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  width: -webkit-fill-available; }

.gooddata-sidebar {
  width: 20%;
  background-color: #f0f0f0;
  padding: 20px;
  -webkit-box-shadow: 2px 0px 5px rgba(0, 0, 0, .1);
          box-shadow: 2px 0px 5px rgba(0, 0, 0, .1); }

.gooddata-sidebar h2 {
  color: #333; }

.gooddata-content {
  width: 100%;
  /* Adjust this as needed */
  min-width: 1171px;
  /* Set a maximum width if you want */
  margin: 0 auto;
  /* Center the iframe container */
  overflow: hidden !important; }

.gooddata-content iframe {
  min-width: 100%;
  width: 100px;
  height: -webkit-fill-available;
  overflow: hidden !important; }

.gooddata-content h2 {
  color: #333;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px; }

.gooddata-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #666; }

/* dashboard.scss */
/* Style for list items */
.gooddata-sidebar ul {
  list-style-type: none;
  padding: 0; }

.gooddata-sidebar li {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease; }

.gooddata-sidebar li:hover {
  background-color: #e0e0e0; }

/* Style for selected list item */
.gooddata-sidebar li.selected {
  background-color: blue;
  color: white; }

.gooddata-modal {
  display: none;
  /* Hide modal by default */
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .4);
  /* Black background with opacity */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* Use flexbox for centering */
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Center horizontally */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* Center vertically */ }

.gooddata-modal.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* Show modal when 'open' class is applied */ }

.gooddata-modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 10px;
  width: 50%;
  /* Adjust width as needed */
  padding: 20px;
  /* Add padding for content */
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
          box-shadow: 0 4px 8px rgba(0, 0, 0, .1); }

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer; }

.close:hover,
.close:focus {
  color: black;
  text-decoration: none; }

h2 {
  margin-top: 0; }

.gooddata-text-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px; }

.gooddata-submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; }

.gooddata-submit-button:hover {
  background-color: #45a049; }

hr.solid {
  border-top: 1.6px solid #bbb;
  border-top: .1rem solid #bbb; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.invoice-funding-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 40px;
  background-color: #f9fdfe;
  width: 100%; }
  @media (max-width: 1024px) {
    .invoice-funding-header {
      max-width: 100%;
      padding: 20px 10px; } }
  .invoice-funding-header a.previous {
    -ms-flex-item-align: start;
        align-self: flex-start;
    color: #5f828c;
    grid-column: 1; }
    .invoice-funding-header a.previous span {
      display: inline-block;
      margin-left: 10px; }
  .invoice-funding-header h3 {
    -ms-flex-item-align: center;
        align-self: center;
    grid-column: 2;
    text-align: center; }
  .invoice-funding-header a.next {
    text-align: right;
    color: #5f828c;
    grid-column: 3; }
    .invoice-funding-header a.next span {
      display: inline-block;
      margin-right: 10px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.bulk-document-downloads__item {
  background: #ffffff;
  border-bottom: solid #F4F4F4 10px; }
  .bulk-document-downloads__item td {
    border-bottom: 0; }

.bulk-document-downloads-status {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }
  .bulk-document-downloads-status.bulk-document-downloads-status--scheduled {
    color: #fff;
    background-color: #6c757d;
    margin-right: 5px; }
  .bulk-document-downloads-status.bulk-document-downloads-status--completed {
    color: #fff;
    background-color: #28a745; }
  .bulk-document-downloads-status.bulk-document-downloads-status--in_progress {
    color: #212529;
    background-color: #ffc107; }
  .bulk-document-downloads-status.bulk-document-downloads-status--failed {
    color: #fff;
    background-color: #dc3545; }

.bulk-document-downloads__item-category {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  color: #fff;
  background-color: #aaaaaa;
  margin-right: 5px; }

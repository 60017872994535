/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.header-building-image-footer {
  font-size: 15px;
  padding: 20px 15px;
  background: #333333;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 70px;
  position: relative;
  bottom: 3px; }
  @media (max-width: 768px) {
    .header-building-image-footer {
      font-size: 14px; } }
  @media (max-width: 659px) {
    .header-building-image-footer {
      padding: 10px 20px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
  .header-building-image-footer .create-job-button {
    display: block;
    white-space: nowrap; }
    @media (max-width: 659px) {
      .header-building-image-footer .create-job-button {
        display: none; } }
  .header-building-image-footer .create-job-button-circle {
    display: none; }
    @media (max-width: 659px) {
      .header-building-image-footer .create-job-button-circle {
        display: block; } }
  .header-building-image-footer .image-footer-text {
    visibility: hidden;
    margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (max-width: 768px) {
      .header-building-image-footer .image-footer-text {
        margin-right: 5px; } }
    @media (max-width: 1024px) {
      .header-building-image-footer .image-footer-text {
        line-height: 1.2em; } }
    .header-building-image-footer .image-footer-text:before {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      vertical-align: middle; }
      @media (max-width: 1024px) {
        .header-building-image-footer .image-footer-text:before {
          display: none; } }
  .header-building-image-footer .building-rating-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .header-building-image-footer .building-rating-container .building-rating {
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .header-building-image-footer .building-rating-container .building-rating:before {
        margin-right: 5px;
        vertical-align: middle; }
        @media (max-width: 768px) {
          .header-building-image-footer .building-rating-container .building-rating:before {
            margin-right: 2px; } }
    .header-building-image-footer .building-rating-container .thumb-button-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .header-building-image-footer .building-rating-container .thumb-button-container .thumb-button {
        border: 1px solid white;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        margin-right: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
        .header-building-image-footer .building-rating-container .thumb-button-container .thumb-button:hover {
          cursor: pointer;
          background-color: black; }
        .header-building-image-footer .building-rating-container .thumb-button-container .thumb-button.active {
          background-color: #F4F4F4; }
  .header-building-image-footer .building-directory-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 399px) {
      .header-building-image-footer .building-directory-container {
        padding-top: 0; } }
    .header-building-image-footer .building-directory-container .avatars-directory-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .header-building-image-footer .building-directory-container .avatars-directory-container .building-avatars {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .header-building-image-footer .building-directory-container .avatars-directory-container .building-avatars .other-avatars-directory-link {
          cursor: pointer; }
      .header-building-image-footer .building-directory-container .avatars-directory-container .building-directory-link {
        padding-top: 15px;
        color: white;
        margin-left: 20px;
        text-decoration: underline;
        cursor: pointer; }
        @media (max-width: 659px) {
          .header-building-image-footer .building-directory-container .avatars-directory-container .building-directory-link {
            font-size: 14px; } }
    .header-building-image-footer .building-directory-container .building-rules-button {
      color: white;
      background-color: transparent;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      padding: 0px 12px;
      padding: 0rem 0.75rem;
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
      .header-building-image-footer .building-directory-container .building-rules-button:hover {
        text-decoration: underline; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.building-main-contacts-container {
  width: 100%;
  padding: 10px;
  color: #000000;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow: auto; }
  .building-main-contacts-container .main-contacts-loading {
    width: 100%; }
  @media (max-width: 1024px) {
    .building-main-contacts-container {
      padding: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media (max-width: 659px) {
    .building-main-contacts-container {
      padding: 15px; } }
  .building-main-contacts-container .building-main-contacts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 1259px) {
      .building-main-contacts-container .building-main-contacts {
        width: 100%;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .building-main-contacts-container .building-main-contacts .strata-manager, .building-main-contacts-container .building-main-contacts .building-manager, .building-main-contacts-container .building-main-contacts .building-team-user {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-right: 15px; }
      @media (max-width: 1259px) {
        .building-main-contacts-container .building-main-contacts .strata-manager, .building-main-contacts-container .building-main-contacts .building-manager, .building-main-contacts-container .building-main-contacts .building-team-user {
          margin-right: 10px; } }
      @media (max-width: 499px) {
        .building-main-contacts-container .building-main-contacts .strata-manager, .building-main-contacts-container .building-main-contacts .building-manager, .building-main-contacts-container .building-main-contacts .building-team-user {
          margin-right: 5px; } }
    .building-main-contacts-container .building-main-contacts .main-contact {
      margin-left: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-size: 14px;
      line-height: 1.4em; }
      @media (max-width: 1259px) {
        .building-main-contacts-container .building-main-contacts .main-contact {
          margin-left: 5px; } }
      @media (max-width: 1024px) {
        .building-main-contacts-container .building-main-contacts .main-contact {
          font-size: 13px; } }
      @media (max-width: 659px) {
        .building-main-contacts-container .building-main-contacts .main-contact {
          font-size: 14px; } }
  @media (max-width: 1024px) {
    .building-main-contacts-container .view-directory-button {
      display: none; } }

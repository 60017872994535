/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.meeting-attachment-thumbnail {
  border-radius: 3px;
  min-height: 80px;
  margin: 0 0 8px;
  overflow: hidden;
  position: relative; }
  .meeting-attachment-thumbnail:hover {
    background-color: rgba(9, 30, 66, .04); }

.meeting-attachment-thumbnail-preview {
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzFweCIgaGVpZ2h0PSI0MHB4IiB2aWV3Qm94PSIwIDAgMzEgNDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5maWxlLWJsdWU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkZpbGVzL0F0dGFjaG1lbnRzL1NtYWxsL0RPQyI+CiAgICAgICAgICAgIDxnIGlkPSJmaWxlLWJsdWUiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTIuNSwwIEwxOS43NDEyLDAgTDMwLjk5OTksMTEuMjE1IEwzMC45OTk5LDM3LjUgQzMwLjk5OTksMzguODgxMiAyOS44OCw0MCAyOC40OTk5LDQwIEwyLjUsNDAgQzEuMTE5OTYsNDAgMi4wNzQyNGUtMDUsMzguODgxMiAyLjA3NDI0ZS0wNSwzNy41IEwyLjA3NDI0ZS0wNSwyLjQ5OTk4IEMyLjA3NDI0ZS0wNSwxLjExODc3IDEuMTIwMDksMCAyLjUsMCBaIiBpZD0iUGF0aCIgZmlsbD0iIzI2QTZEMSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTMwLjk2MzgsMTEuMjUgTDIyLjI1LDExLjI1IEMyMC44Njk5LDExLjI1IDE5Ljc1LDEwLjEzMDEgMTkuNzUsOC43NTAwNCBMMTkuNzUsMC4wMjUwMjQ0IEwzMC45NjM4LDExLjI1IFoiIGlkPSJQYXRoIiBmaWxsPSIjMUU3RUEwIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 3px;
  height: 80px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  text-decoration: underline;
  z-index: 1;
  width: 90px; }

.meeting-attachment-thumbnail-details {
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8px 35px 8px 108px;
  min-height: 80px;
  margin: 0;
  z-index: 0; }

.meeting-attachment-thumbnail-details .meeting-attachment-thumbnail-name {
  font-weight: 600;
  word-wrap: break-word;
  text-transform: none;
  padding: 0;
  display: block; }
  .meeting-attachment-thumbnail-details .meeting-attachment-thumbnail-name.meeting-attachment-thumbnail-name-normal {
    font-weight: normal; }
  .meeting-attachment-thumbnail-details .meeting-attachment-thumbnail-name.meeting-attachment-thumbnail-name-black {
    color: black; }

.meeting-attachment-thumbnail-actions {
  position: absolute;
  top: 1%;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0; }

.meeting-attachment-thumbnail:hover > .meeting-attachment-thumbnail-actions {
  opacity: 1; }

.react-select__control.react-select__control--is-focused {
  border-color: transparent !important; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dropzone-modal .modal-content {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: hidden; }
  .dropzone-modal .modal-content .modal-cross-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
    .dropzone-modal .modal-content .modal-cross-close:before {
      height: 18px;
      width: 18px;
      background-color: #EAEDF0;
      border: 6px solid #EAEDF0;
      border-radius: 50%; }
  .dropzone-modal .modal-content .image-modal-container {
    padding: 15px 0 0; }
    .dropzone-modal .modal-content .image-modal-container .image_box img {
      height: 200px;
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      border-radius: 4px; }
    .dropzone-modal .modal-content .image-modal-container .image-description {
      margin: 0;
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      font-weight: 600; }

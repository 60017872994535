/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.auth-form {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .auth-form .main-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  @media (max-width: 499px) {
    .auth-form {
      min-height: 600px;
      padding: 0; } }
  @media (max-width: 399px) {
    .auth-form {
      min-height: 500px; } }
  @media (max-width: 339px) {
    .auth-form {
      min-height: 400px; } }
  .auth-form .auth-form__logo-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 70px; }
  .auth-form .auth-form-background {
    background: #ffffff;
    border-radius: 5px;
    min-width: 390px;
    padding: 40px 30px;
    margin: auto; }
    @media (max-width: 659px) {
      .auth-form .auth-form-background {
        min-width: 340px; } }
  .auth-form .auth-form-title {
    margin: 22px 0 33px 0; }
    @media (max-width: 499px) {
      .auth-form .auth-form-title {
        margin: 12px 0 23px 0; } }
  .auth-form .next-button {
    width: 100%;
    height: 42px;
    margin-top: 10px; }
    .auth-form .next-button.login-button {
      margin-top: 14px; }
  .auth-form p {
    font-size: 14px;
    font-weight: bold;
    text-align: center; }
    @media (max-width: 339px) {
      .auth-form p {
        font-size: 12px; } }
    .auth-form p > span {
      font-weight: 400; }
  .auth-form .button-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 10px;
    width: 100%; }
    .auth-form .button-grid .left-button {
      grid-column: 1 / span 1;
      margin-top: 20px; }
    .auth-form .button-grid .right-button {
      grid-column: 2 / span 1;
      margin-top: 20px; }
  .auth-form label {
    font-size: 14px;
    margin-bottom: 8px; }
    @media (max-width: 339px) {
      .auth-form label {
        font-size: 12px; } }
  @media (max-width: 399px) {
    .auth-form span {
      line-height: 1.2em; } }

.auth-form__title {
  padding: 8px;
  padding: .5rem;
  margin: 24px 0 16px 0;
  margin: 1.5rem 0 1rem 0;
  text-align: center; }

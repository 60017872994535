/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.alice-carousel__stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.alice-carousel__stage-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  height: auto; }

@media (max-width: 1024px) {
  .upcoming-work-container {
    padding: 0; } }

@media (max-width: 659px) {
  .upcoming-work-container {
    max-width: calc(100vw - 85px);
    border-bottom: 1px solid #E2E2E2; } }

@media (max-width: 499px) {
  .upcoming-work-container {
    max-width: 100%; } }

@media (max-width: 399px) {
  .upcoming-work-container {
    max-width: 100%; } }

.upcoming-work-container .upcoming-work-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 30px 0; }
  @media (max-width: 659px) {
    .upcoming-work-container .upcoming-work-header {
      margin: 0 0 30px; } }
  .upcoming-work-container .upcoming-work-header .all-work-button {
    text-transform: none;
    padding: 0 20px;
    min-width: 110px; }

.upcoming-work-container .upcoming-work-items-container.not-carousel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, .1); }
  @media (max-width: 1024px) {
    .upcoming-work-container .upcoming-work-items-container.not-carousel {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 659px) {
    .upcoming-work-container .upcoming-work-items-container.not-carousel {
      display: block;
      padding-bottom: 20px; } }

.upcoming-work-container .upcoming-work-items-container .alice-carousel .alice-carousel__dots-item {
  background-color: #cecece; }
  .upcoming-work-container .upcoming-work-items-container .alice-carousel .alice-carousel__dots-item:hover, .upcoming-work-container .upcoming-work-items-container .alice-carousel .alice-carousel__dots-item.__active {
    background-color: #333333; }

.upcoming-work-container .upcoming-work-items-container.inactive {
  display: none; }

.upcoming-work-container span.date.overdue-date {
  border: thin solid #D4504B;
  color: #D4504B; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.document-list-body .main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.document-list-body .loading {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.dms-scroll-wrapper {
  overflow-x: auto; }

.document-list-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.document-list-items {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto; }

.documents-list-view {
  min-width: 720px; }

.invoices-list-view {
  min-width: 975px; }

.cancel-hold-invoice-container {
  padding: 0 15px; }
  .cancel-hold-invoice-container .hidden {
    display: none; }
  .cancel-hold-invoice-container .cancel-hold-invoice-title {
    margin-bottom: 20px; }
  .cancel-hold-invoice-container div.avatar {
    margin-right: 5px; }
  .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container {
    width: 100%;
    margin: 10px 0 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .go-back-button {
      width: 49%;
      border: 1px solid #333333;
      background: #ffffff;
      color: #353535; }
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .go-back-button:hover {
        background-color: #000000;
        color: #ffffff; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .cancel-invoice-button {
      background-color: #D4504B;
      border: 1px solid #D4504B;
      width: 49%; }
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .cancel-invoice-button:hover {
        background-color: #000000;
        color: #ffffff; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .on-hold-button,
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .approve-next-button {
      background-color: #4FCBB2;
      border: 1px solid #4FCBB2;
      width: 49%; }
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .on-hold-button:hover,
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .approve-next-button:hover {
        background-color: #000000;
        color: #ffffff; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .approve-next-button {
      width: 100%; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .approve-go-to-next-button {
      background-color: #3bc5a9;
      border: 1px solid #3bc5a9;
      width: 100%; }
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .approve-go-to-next-button:hover {
        background-color: #000000;
        color: #ffffff; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .override-approve-button {
      width: 100%;
      background-color: #DC3545;
      border: #DC3545; }
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .override-approve-button:hover {
        background-color: #000000;
        color: #ffffff; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .override-goto-next-button {
      background-color: #d32535;
      border: 1px solid #d32535;
      width: 100%; }
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .override-goto-next-button:hover {
        background-color: #000000;
        color: #ffffff; }
    .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .override-goto-next-strata-invoice-button {
      background-color: #921925;
      border: 1px solid #921925;
      width: 100%; }
      .cancel-hold-invoice-container .cancel-hold-invoice-buttons-container .override-goto-next-strata-invoice-button:hover {
        background-color: #000000;
        color: #ffffff; }
  .cancel-hold-invoice-container .approve-warning-text {
    margin-bottom: 5px; }
  .cancel-hold-invoice-container .cancel-notification-danger {
    color: #DC3545; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.contact-details-modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 20px; }
  .contact-details-modal-container .contact-details-avatar-wrapper {
    margin-left: 5px; }
  .contact-details-modal-container .contact-details-body-wrapper {
    margin-left: 10px; }
    .contact-details-modal-container .contact-details-body-wrapper .contact-details-heading {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 5px; }
      .contact-details-modal-container .contact-details-body-wrapper .contact-details-heading h4 .contact-details-role-text {
        text-transform: capitalize; }
    .contact-details-modal-container .contact-details-body-wrapper .contact-details-subheading {
      font-weight: bolder; }
    .contact-details-modal-container .contact-details-body-wrapper .contact-details-display-fields .contact-details-message-button {
      font-size: 14px;
      text-decoration: underline;
      color: #797979; }

.contact-details-back-button-container {
  text-align: right; }
  .contact-details-back-button-container .button {
    margin-top: 10px;
    padding: 5px 15px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.messaging-sidebar-container {
  -webkit-box-flex: 0.25;
      -ms-flex: 0.25;
          flex: 0.25;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #ffffff;
  border-right: 2px solid #dbdbdb;
  height: 100%;
  position: relative;
  -ms-flex-positive: 0.25;
      flex-grow: 0.25;
  overflow-x: hidden; }
  .messaging-sidebar-container .message-sidebar-search-box, .messaging-sidebar-container .message-sidebar-filter-togglers {
    color: rgba(53, 53, 53, .7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
    padding: 15px; }
  .messaging-sidebar-container .message-sidebar-search-box {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .messaging-sidebar-container .message-sidebar-search-box .new-msg-button {
      padding: 13px;
      line-height: 0;
      margin-left: 10px; }
      @media (max-width: 1024px) {
        .messaging-sidebar-container .message-sidebar-search-box .new-msg-button {
          padding: 10px; } }
    .messaging-sidebar-container .message-sidebar-search-box .search-input {
      border-left: none;
      max-width: none; }
  .messaging-sidebar-container .message-sidebar-filter-togglers {
    border-bottom: 2px solid #dbdbdb; }
    .messaging-sidebar-container .message-sidebar-filter-togglers .toggle {
      margin-left: 15px; }
    .messaging-sidebar-container .message-sidebar-filter-togglers .toggle:first-child {
      margin-left: 0; }
    .messaging-sidebar-container .message-sidebar-filter-togglers .checkboxes-field {
      margin-left: 16px;
      margin-left: 1rem; }
  .messaging-sidebar-container .close-preview-button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: 10px 10px;
    background: #F4F4F4; }
    @media (min-width: 1025px) {
      .messaging-sidebar-container .close-preview-button-container {
        display: none; } }
    .messaging-sidebar-container .close-preview-button-container button.show-message-content-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border: 0;
      background: transparent !important;
      font-size: 14px;
      color: rgba(53, 53, 53, .7); }
      .messaging-sidebar-container .close-preview-button-container button.show-message-content-button:after {
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
      @media (min-width: 1025px) {
        .messaging-sidebar-container .close-preview-button-container button.show-message-content-button {
          display: none;
          padding: 0; } }
      @media (max-width: 1024px) {
        .messaging-sidebar-container .close-preview-button-container button.show-message-content-button.inactive {
          display: none; } }
  .messaging-sidebar-container .chatroom-filter-result-container {
    overflow-y: hidden;
    visibility: visible;
    scrollbar-gutter: stable; }
  .messaging-sidebar-container .chatroom-filter-result-container:hover {
    overflow-y: auto; }
  .messaging-sidebar-container .link-anchor {
    text-decoration: none; }
  .messaging-sidebar-container .messages-fetch-more {
    position: absolute;
    left: 50%;
    bottom: 5px;
    -webkit-transform: translate(-50%, -20%);
            transform: translate(-50%, -20%); }
    .messaging-sidebar-container .messages-fetch-more button {
      border-radius: 20px;
      opacity: 0.8; }
    .messaging-sidebar-container .messages-fetch-more button:hover {
      background: #4FCBB2;
      border: #4FCBB2;
      opacity: 1; }
  @media (max-width: 1024px) {
    .messaging-sidebar-container {
      height: 100%;
      min-width: -webkit-fill-available; }
      .messaging-sidebar-container.inactive {
        display: none; }
      .messaging-sidebar-container .chatroom-filter-result-container {
        overflow-y: auto; } }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.privacy-settings-container form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px; }

.privacy-settings-container .hidden {
  visibility: hidden !important; }

.privacy-settings-container .field-heading {
  border-width: 0; }

.privacy-settings-container .field-wrapper {
  margin-top: 20px; }

.privacy-settings-container .fields-snooze {
  width: 50%; }

.privacy-settings-container button[type='submit'] {
  margin-top: 20px; }

.privacy-settings-container .privacy-settings-snooze {
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid #eee; }
  .privacy-settings-container .privacy-settings-snooze .snooze-fields {
    width: 30%; }

.privacy-settings-container .privacy-settings-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.privacy-settings-container .privacy-settings-header {
  padding: 5px 30px 20px; }
  .privacy-settings-container .privacy-settings-header .settings-header {
    font-weight: bold; }
    .privacy-settings-container .privacy-settings-header .settings-header.activity-setting {
      grid-column: 1 / span 4; }
    .privacy-settings-container .privacy-settings-header .settings-header.setting-toggle {
      grid-column: span 1;
      justify-self: center; }
    .privacy-settings-container .privacy-settings-header .settings-header.email-frequency {
      grid-column: 8 / span 5;
      justify-self: center; }

.privacy-settings-container .privacy-settings-toggle .icon-after::after {
  width: 9px;
  height: 9px;
  margin-left: 9px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s; }

.privacy-settings-container .privacy-settings-toggle .active.icon-after::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.privacy-settings-container .privacy-settings-item-container {
  max-width: 1460px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 15px 30px;
  margin-bottom: 10px; }

.privacy-settings-container .privacy-settings-item .field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.privacy-settings-container .privacy-settings-item .field-heading {
  grid-column: 1 / span 11;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .privacy-settings-container .privacy-settings-item .field-heading .hint {
    font-size: 90%;
    color: #999; }

.privacy-settings-container .privacy-settings-item .field-toggle {
  grid-column: span 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.privacy-settings-container button[type="submit"] {
  margin-top: 10px;
  float: right; }

.privacy-settings-container .form-actions {
  overflow: hidden; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.digital-noticeboard-carousel-item {
  margin: 0 10px;
  background-color: #fff;
  position: relative;
  border: 1px solid #ddd;
  padding: 5px 0; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview {
    padding: 20px 10px 5px 10px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__header {
    margin-bottom: 8px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__content {
    margin-bottom: 10px;
    height: 300px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__message-box--primary {
    border-radius: 10px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__heading-text {
    padding: 20px 12.5px 0 12.5px;
    font-size: 17px;
    font-weight: 550;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    line-height: 1.2; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__main-copy-text {
    padding: 12.5px 12.5px 0 12.5px;
    font-size: 12px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__message-box--alert > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 7;
    /* number of lines to show */
    line-clamp: 7; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__message-box--message > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 15;
    /* number of lines to show */
    line-clamp: 15; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__message-box--news > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 7;
    /* number of lines to show */
    line-clamp: 7; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__message-box--welcome > .digital-noticeboard-preview__main-copy-text {
    -webkit-line-clamp: 8;
    /* number of lines to show */
    line-clamp: 8; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__message-box--news {
    border-top-left-radius: unset;
    border-top-right-radius: unset; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__news-image-placeholder {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    .digital-noticeboard-carousel-item .digital-noticeboard-preview__news-image-placeholder span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 17px;
      font-weight: 550; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__news-image {
    padding-bottom: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__extra-copy-and-qr {
    margin-top: 10px;
    gap: 10px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__extra-copy-text {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    border-radius: 10px;
    padding: 7.5px;
    font-size: 8px;
    line-height: 1.3;
    -webkit-line-clamp: 8;
    /* number of lines to show */
    line-clamp: 8; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__qr-image-placeholder {
    border-radius: 10px;
    padding: 7.5px; }
    .digital-noticeboard-carousel-item .digital-noticeboard-preview__qr-image-placeholder span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 17px;
      font-weight: 550; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__clock {
    padding: 2.5px 3.5px;
    border-radius: 3.5px;
    font-weight: 600;
    font-size: 10px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__message-box--secondary {
    padding: 7.5px;
    border-radius: 10px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-date {
    position: absolute;
    top: -2.5px;
    left: -4px;
    padding: 10px 2.5px 10px 10px;
    width: 45px;
    height: 45px; }
    .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-date .digital-noticeboard-preview__event-month {
      font-weight: 520;
      font-size: 9px;
      line-height: 9px;
      letter-spacing: -0.5px; }
    .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-date .digital-noticeboard-preview__event-day-of-month {
      font-weight: 700;
      font-size: 19px;
      letter-spacing: -1px; }
      .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-date .digital-noticeboard-preview__event-day-of-month sup {
        letter-spacing: -0.6px;
        font-weight: 520;
        font-size: 8.5px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-time {
    margin-left: 40px;
    font-weight: 510;
    font-size: 8.5px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-week-day {
    margin-left: 40px;
    font-weight: 800;
    font-size: 12.5px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-heading {
    margin-top: 7.5px;
    font-size: 12px;
    font-weight: 1000;
    letter-spacing: -.5px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-description {
    margin-top: 2.5px;
    font-size: 7.5px; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-location {
    margin-top: 10px;
    gap: 7.5px; }
    .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-location .marker {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 10px;
              flex: 0 0 10px;
      width: 10px;
      height: 10px; }
      .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-location .marker:after {
        content: '';
        width: 5px;
        height: 5px;
        margin: 5px 0 0 5px; }
    .digital-noticeboard-carousel-item .digital-noticeboard-preview__event-location .digital-noticeboard-preview__event-location-text {
      font-size: 7.5px;
      font-weight: 520; }
  .digital-noticeboard-carousel-item .digital-noticeboard-preview-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 0;
    background-color: #fff; }

.digital-noticeboard-carousel-item__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  padding: 5px;
  width: 250px; }
  .digital-noticeboard-carousel-item__header button {
    padding: 0.2em 0.4em; }
  .digital-noticeboard-carousel-item__header .digital-noticeboard-carousel-item__status {
    font-weight: 600;
    color: #ffffff;
    padding: 3px 5px;
    border-radius: 5px; }
    .digital-noticeboard-carousel-item__header .digital-noticeboard-carousel-item__status.digital-noticeboard-carousel-item--active {
      background-color: #4FCBB2; }
    .digital-noticeboard-carousel-item__header .digital-noticeboard-carousel-item__status.digital-noticeboard-carousel-item--draft {
      background-color: #F5BF65; }
    .digital-noticeboard-carousel-item__header .digital-noticeboard-carousel-item__status.digital-noticeboard-carousel-item--expired {
      background-color: #EAEDF0; }

.digital-noticeboard-carousel-item__body {
  margin: auto;
  padding: 5px;
  width: 250px; }

.digital-noticeboard-carousel-item__footer {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5px; }

.digital-noticeboard-carousel-item__time-frame {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis; }

.digital-noticeboard-carousel-item__screen-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis; }

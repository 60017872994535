/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.building-document-category-card {
  border: 1.5px solid #DFDFDF;
  margin: 15px 0;
  padding: 15px;
  position: relative;
  border-radius: 10px; }
  .building-document-category-card:hover {
    background-color: #F8F8F8; }
  .building-document-category-card .action-buttons {
    position: absolute;
    top: 5px;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 1; }
  .building-document-category-card hr {
    border: 1.5px solid #DFDFDF;
    border-radius: 0.75px;
    margin: 10px 0 15px 0; }

.document-rule-card:hover > .document-rule-actions {
  opacity: 1; }

.search-input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.footer-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.react-tabs__tab-panel .loading {
  height: 20vh; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.single-job-container.inactive {
  display: none; }

.single-job-container.active .single-job-address, .single-job-container.active .single-job-title {
  margin-bottom: 15px; }

.single-job-container.active .single-job-reference {
  margin-bottom: 5px; }

.single-job-container.active .single-job-subtitle {
  margin-top: 15px;
  margin-bottom: 5px; }

.single-job-container.active .job-images-container {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px; }
  .single-job-container.active .job-images-container .job-image {
    -o-object-fit: cover;
       object-fit: cover; }

.single-job-container.active .job-history-container {
  margin-top: 20px; }
  .single-job-container.active .job-history-container .all-history-container {
    padding: 10px;
    border: 1px solid #cacaca;
    border-radius: 5px; }
    .single-job-container.active .job-history-container .all-history-container .single-history-item-container:first-of-type {
      color: #0079ff; }
    .single-job-container.active .job-history-container .all-history-container .single-history-item-container:last-of-type .history-date-description-container .work-green-line {
      display: none; }
    .single-job-container.active .job-history-container .all-history-container .single-history-item-container .history-date-description-container .history-date-description {
      padding: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .single-job-container.active .job-history-container .all-history-container .single-history-item-container .history-date-description-container .history-date-description .history-date {
        font-weight: bold; }
      .single-job-container.active .job-history-container .all-history-container .single-history-item-container .history-date-description-container .history-date-description .history-description {
        margin-left: 10px; }
    .single-job-container.active .job-history-container .all-history-container .single-history-item-container .history-date-description-container .work-green-line {
      height: 15px;
      width: 3px;
      background-color: #333333;
      margin-left: 50px; }

.single-job-container.active .job-history-back-button {
  width: 100%;
  margin-top: 15px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.invoice-action-container.active {
  position: absolute;
  text-align: center;
  width: 90%;
  top: -100%;
  left: 5%;
  background-color: #333333;
  z-index: 50;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: bounce 0.75s;
          animation: bounce 0.75s; }

.invoice-action-container.inactive {
  display: none; }

.invoice-action-container:before {
  content: '';
  display: block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333333;
  position: absolute;
  bottom: -5px;
  left: 50%; }

.invoice-action-container .invoice-action-text {
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px; }
  .invoice-action-container .invoice-action-text:before {
    margin: 0 10px 0 0; }

.invoice-action-container .invoice-action-button-container {
  -ms-flex-item-align: start;
      align-self: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 10px 15px 10px 5px; }
  .invoice-action-container .invoice-action-button-container .invoice-action-button {
    border: none;
    padding: 5px 20px;
    text-transform: none;
    width: 100px;
    border-radius: 10px; }
    .invoice-action-container .invoice-action-button-container .invoice-action-button.reject-button {
      background-color: #D4504B;
      margin-right: 10px; }
      .invoice-action-container .invoice-action-button-container .invoice-action-button.reject-button:hover {
        background-color: #be332e; }
    .invoice-action-container .invoice-action-button-container .invoice-action-button.approve-button:hover {
      background-color: #35b299; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  75% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  75% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

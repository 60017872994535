/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.alice-carousel__wrapper {
  padding: 4.8px 0;
  padding: .3rem 0;
  border-radius: .3rem;
  border: 1px solid rgba(0, 0, 0, .1); }

.meeting-carousel-item {
  position: relative;
  background-color: #fff;
  margin: 0 4.8px;
  margin: 0 .3rem;
  border-radius: .3rem; }
  .meeting-carousel-item .meeting-carousel-item__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .meeting-carousel-item .meeting-carousel-item--day {
    min-width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .meeting-carousel-item .meeting-carousel-item--day span {
      margin-left: 8px;
      margin-left: .5rem;
      min-width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .meeting-carousel-item .meeting-carousel-item--year {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .meeting-carousel-item .meeting-carousel-item__body--left {
    padding: 16px 8px;
    padding: 1rem .5rem;
    width: 45%; }
  .meeting-carousel-item .meeting-carousel-item__body--right {
    width: 55%;
    padding: 16px 8px;
    padding: 1rem .5rem;
    padding-left: 16px;
    padding-left: 1rem;
    border-left: 2px solid #4FCBB2;
    overflow: hidden; }
  .meeting-carousel-item .meeting-carousel-item--time {
    min-width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .meeting-carousel-item .meeting-carousel-item--time span {
      margin-left: 8px;
      margin-left: .5rem;
      min-width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.meeting-carousel-item__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  padding: 16px;
  padding: 1rem;
  padding-bottom: 8px;
  padding-bottom: .5rem;
  width: 320px;
  background-color: #fff; }

.meeting-carousel-item__header .meeting-carousel-item--active {
  color: #4FCBB2; }

.meeting-carousel-item__header .meeting-carousel-item--draft {
  color: #F5BF65; }

.meeting-carousel-item__header .meeting-carousel-item--expired {
  color: #EAEDF0; }

.meeting-carousel-item__body {
  margin: auto;
  padding: 8px;
  padding: .5rem;
  width: 320px;
  background-color: #fff; }

.meeting-carousel-item__footer {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 8px;
  padding: .5rem;
  width: 320px;
  background-color: #fff; }
  .meeting-carousel-item__footer .meeting-carousel-item--location {
    padding: 16px 8px;
    padding: 1rem .5rem;
    padding-top: 0;
    min-width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .meeting-carousel-item__footer .meeting-carousel-item--location span {
      margin-left: 8px;
      margin-left: .5rem;
      min-width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.meeting-carousel-item a.vote-button:hover {
  opacity: 0.8; }

.meeting-carousel-item--unvoted .meeting-carousel-item__body--right {
  border-left: 2px solid #F5BF65; }

.meeting-carousel-item--unvoted a.vote-button {
  border: 1px solid #F5BF65;
  color: #fff;
  background: #F5BF65; }

.meeting-carousel-item--voted .meeting-carousel-item__body--right {
  border-left: 2px solid #4FCBB2; }

.meeting-carousel-item--voted a.vote-button {
  border: 1px solid #4FCBB2;
  color: #fff;
  background: #4FCBB2; }

.meeting-carousel-item--non-votable .meeting-carousel-item__body--right {
  border-left: 2px solid #6c757d; }

.meeting-carousel-item--non-votable a.vote-button {
  background: #333333;
  border-color: #333333;
  color: #ffffff; }

@charset "UTF-8";
/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.document-content .download-button {
  text-transform: none;
  padding: 2px 15px;
  border-radius: 8px; }

.document-content .organisation-logo-container {
  margin: 10px 0 0 10px; }

.document-content .sidebar-building-info .building-info-text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .document-content .sidebar-building-info .building-info-text-container .building-info-text {
    margin-right: 10px; }
    .document-content .sidebar-building-info .building-info-text-container .building-info-text:before {
      content: '\2022';
      margin-right: 5px; }

.document-content .sidebar-building-category .sidebar-category-items {
  margin: 5px 0; }

.document-content .icon-download {
  border: none;
  background-color: inherit;
  font-size: 16px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.strata-data-logs-list-body .main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.strata-data-logs-list-body .loading {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.strata-data-logs-list-body .heading-title {
  text-align: center;
  padding: 15px; }

.strata-data-logs-list-body .strata-data-logs-list-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.strata-data-logs-list-body .strata-data-logs-list-items {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto; }
  .strata-data-logs-list-body .strata-data-logs-list-items button.primary {
    margin: 5px; }

.strata-data-logs-list-body .strata-data-logs-view-block-content {
  background: #ffffff;
  padding: 15px;
  border-radius: 0 0 5px 5px;
  overflow: visible; }

.strata-data-logs-list-body .table-wrapper {
  overflow-x: auto;
  margin-bottom: 16px;
  margin-bottom: 1rem; }

.strata-data-logs-list-body table.building-detail-item {
  margin-bottom: unset; }
  .strata-data-logs-list-body table.building-detail-item thead th.capitalize {
    text-transform: capitalize; }
  .strata-data-logs-list-body table.building-detail-item tr.bg-grey {
    background-color: gainsboro; }
  .strata-data-logs-list-body table.building-detail-item tr.bg-light-red {
    background-color: #ffe8e7; }
  .strata-data-logs-list-body table.building-detail-item tbody td.capitalize {
    text-transform: capitalize; }
  .strata-data-logs-list-body table.building-detail-item tbody td button.primary {
    margin: 5px; }
  .strata-data-logs-list-body table.building-detail-item tbody td .array-column {
    white-space: pre; }
  .strata-data-logs-list-body table.building-detail-item .component-loading {
    padding-top: unset; }

.strata-data-logs-list-body .strata-data-logs-select-orgs {
  min-width: 350px;
  max-width: 700px;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .strata-data-logs-list-body .strata-data-logs-select-orgs .select-org-input {
    width: 80%;
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding-right: 15px; }
    .strata-data-logs-list-body .strata-data-logs-select-orgs .select-org-input .css-1hwfws3 {
      min-height: 50px; }
  .strata-data-logs-list-body .strata-data-logs-select-orgs .component-loading {
    padding: unset;
    width: auto; }
    .strata-data-logs-list-body .strata-data-logs-select-orgs .component-loading .spinner {
      width: 50px;
      height: 50px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.header-building-image-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 20px 20px 30px;
  -ms-flex-line-pack: stretch;
      align-content: stretch; }
  @media (max-width: 1024px) {
    .header-building-image-container {
      padding: 20px 20px 20px 15px; } }
  @media (max-width: 768px) {
    .header-building-image-container {
      padding: 20px 10px; } }
  @media (max-width: 659px) {
    .header-building-image-container {
      padding: 5px 15px;
      border-bottom: 1px solid #333333;
      margin-bottom: 20px; } }
  .header-building-image-container .header-building-image {
    height: 330px; }
    @media (max-width: 659px) {
      .header-building-image-container .header-building-image {
        height: 270px; } }
  .header-building-image-container .building-image-container {
    position: relative; }
    .header-building-image-container .building-image-container .alice-carousel .alice-carousel__prev-btn {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50px;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .header-building-image-container .building-image-container .alice-carousel .alice-carousel__prev-btn .alice-carousel__prev-btn-wrapper .alice-carousel__prev-btn-item {
        margin: 0;
        height: 34px;
        width: 34px;
        padding-top: 8px;
        padding-right: 6px;
        background: rgba(255, 255, 255, .6);
        border-radius: 100px; }
        .header-building-image-container .building-image-container .alice-carousel .alice-carousel__prev-btn .alice-carousel__prev-btn-wrapper .alice-carousel__prev-btn-item span {
          border: solid #52cbb2;
          border-width: 0 4px 4px 0;
          display: inline-block;
          padding: 7px;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg); }
          .header-building-image-container .building-image-container .alice-carousel .alice-carousel__prev-btn .alice-carousel__prev-btn-wrapper .alice-carousel__prev-btn-item span:after {
            content: ''; }
    .header-building-image-container .building-image-container .alice-carousel .alice-carousel__next-btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .header-building-image-container .building-image-container .alice-carousel .alice-carousel__next-btn .alice-carousel__next-btn-wrapper .alice-carousel__next-btn-item {
        margin: 0;
        margin: 0;
        height: 34px;
        width: 34px;
        padding-top: 8px;
        padding-left: 6px;
        background: rgba(255, 255, 255, .6);
        border-radius: 100px; }
        .header-building-image-container .building-image-container .alice-carousel .alice-carousel__next-btn .alice-carousel__next-btn-wrapper .alice-carousel__next-btn-item span {
          border: solid #52cbb2;
          border-width: 0 4px 4px 0;
          display: inline-block;
          padding: 7px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg); }
          .header-building-image-container .building-image-container .alice-carousel .alice-carousel__next-btn .alice-carousel__next-btn-wrapper .alice-carousel__next-btn-item span:after {
            content: ''; }
    .header-building-image-container .building-image-container .make_main-image {
      position: absolute;
      right: 80px;
      bottom: 12px;
      padding: 5px 8px;
      background: rgba(0, 0, 0, .6);
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      text-decoration: none;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      cursor: pointer; }
      .header-building-image-container .building-image-container .make_main-image:hover {
        background-color: #52cbb2; }
    .header-building-image-container .building-image-container .building-image {
      width: 100%;
      height: 330px;
      -o-object-fit: cover;
         object-fit: cover; }
      @media (max-width: 659px) {
        .header-building-image-container .building-image-container .building-image {
          height: 270px; } }
    .header-building-image-container .building-image-container .upload-building-image {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-color: #333333;
      border-radius: 50%;
      border: 1px solid #ffffff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .header-building-image-container .building-image-container .upload-building-image:before {
        height: 12px;
        width: 12px; }
      .header-building-image-container .building-image-container .upload-building-image:hover {
        cursor: pointer; }
    .header-building-image-container .building-image-container .delete-building-image {
      position: absolute;
      bottom: 10px;
      right: 45px;
      width: 30px;
      height: 30px;
      background-color: #333333;
      border-radius: 50%;
      border: 1px solid #ffffff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .header-building-image-container .building-image-container .delete-building-image:before {
        height: 12px;
        width: 12px; }
      .header-building-image-container .building-image-container .delete-building-image:hover {
        cursor: pointer; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.upcoming-work-single-item-container {
  margin: 0 5px;
  padding: 20px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  background-color: #ffffff;
  height: 100%; }
  .upcoming-work-single-item-container .work-description {
    margin-top: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-width: 100%;
    line-height: 1.4em;
    margin-right: 20px;
    height: 45px; }
    @media (max-width: 659px) {
      .upcoming-work-single-item-container .work-description {
        font-size: 15px; } }
  .upcoming-work-single-item-container .work-contractor {
    color: #939393;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-width: 100%; }
    @media (max-width: 659px) {
      .upcoming-work-single-item-container .work-contractor {
        font-size: 14px; } }
  .upcoming-work-single-item-container .upcoming-work {
    margin-bottom: 8px; }
  .upcoming-work-single-item-container .avatar-title-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .upcoming-work-single-item-container .avatar-title-container .subtitle {
      margin-left: 15px;
      cursor: pointer;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-width: 100%;
      min-height: 38px;
      margin-top: 10px; }
    .upcoming-work-single-item-container .avatar-title-container .avatar {
      margin-right: -5px; }
  .upcoming-work-single-item-container .date-status-container {
    margin-bottom: 20px; }
    @media (max-width: 1259px) {
      .upcoming-work-single-item-container .date-status-container {
        font-size: 13px; } }
    @media (max-width: 768px) {
      .upcoming-work-single-item-container .date-status-container {
        font-size: 12px; } }
    .upcoming-work-single-item-container .date-status-container .date {
      padding: 5px 15px;
      border: 1px solid #333333;
      border-radius: 10px;
      text-align: center;
      width: auto;
      margin-right: 5px;
      font-size: 15px; }
    .upcoming-work-single-item-container .date-status-container .contractor-and-status {
      font-size: 16px;
      font-size: 1rem;
      white-space: normal;
      text-align: start; }
      .upcoming-work-single-item-container .date-status-container .contractor-and-status:nth-child(n + 1) {
        margin-top: 3.2px;
        margin-top: 0.2rem; }
    .upcoming-work-single-item-container .date-status-container .contractor-and-status-show-more {
      font-family: 'Lato', Sans-Serif;
      margin-top: 0.3rex;
      background: none;
      border: none; }
    .upcoming-work-single-item-container .date-status-container .status {
      font-size: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 5px 15px;
      border-radius: 8px;
      text-align: center;
      width: auto;
      background-color: #4FCBB2;
      color: #ffffff;
      line-height: 1;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .upcoming-work-single-item-container .date-status-container .status.underreview {
        background-color: #333333; }
      .upcoming-work-single-item-container .date-status-container .status.date-btn {
        font-size: 15px;
        background-color: transparent;
        border: 1px solid #353535;
        margin: 0 5px 0 0;
        color: #353535; }
      .upcoming-work-single-item-container .date-status-container .status.jtc-status {
        margin: 0 5px 0 0; }
  .upcoming-work-single-item-container .date-status-container.upcoming-work .avatar .avatar-photo {
    margin: 0 5px 0 0; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
#compare-and-connect-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  #compare-and-connect-container .compare-and-connect-iframe-wrapper {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 3;
        -ms-flex: 3 1 0px;
            flex: 3 1 0; }
    @media (max-width: 1259px) {
      #compare-and-connect-container .compare-and-connect-iframe-wrapper {
        margin: 0; } }
  #compare-and-connect-container .compare-and-connect-iframe {
    margin-bottom: 20px; }
  #compare-and-connect-container .compare-connect-alert-container-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background: #4FCBB2;
    width: 100%;
    padding: 0 20px;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 50px;
    z-index: 1; }
    @media (max-width: 768px) {
      #compare-and-connect-container .compare-connect-alert-container-bottom {
        padding: 0 10px; } }
  #compare-and-connect-container .mw-100 {
    max-width: 100%; }
  #compare-and-connect-container .compare-connect-step-container {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
    padding: 0; }
    #compare-and-connect-container .compare-connect-step-container.display-none {
      display: none; }
    #compare-and-connect-container .compare-connect-step-container .left-banner-wrapper {
      height: 100%;
      background-color: #F4F4F4;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 0px;
              flex: 1 1 0; }
      @media (max-width: 1259px) {
        #compare-and-connect-container .compare-connect-step-container .left-banner-wrapper {
          display: none; } }
    #compare-and-connect-container .compare-connect-step-container .right-banner-wrapper {
      height: 100%;
      background-color: #F4F4F4;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 0px;
              flex: 1 1 0; }
      @media (max-width: 1259px) {
        #compare-and-connect-container .compare-connect-step-container .right-banner-wrapper {
          display: none; } }
    #compare-and-connect-container .compare-connect-step-container .left-banner, #compare-and-connect-container .compare-connect-step-container .right-banner {
      height: 100%;
      z-index: 1; }
    #compare-and-connect-container .compare-connect-step-container .left-banner > img, #compare-and-connect-container .compare-connect-step-container .right-banner > img {
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: fill;
         object-fit: fill; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
body {
  background: #F4F4F4; }

.document-view-wrapper {
  display: grid;
  grid-template-columns: 0 50% 50%; }
  @media (max-width: 1024px) {
    .document-view-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      overflow-x: hidden; } }

.document-view-left {
  padding: 30px 15px 30px 0; }
  @media (max-width: 1024px) {
    .document-view-left {
      padding: 30px 0; } }
  @media (max-width: 768px) {
    .document-view-left {
      padding: 10px 0; } }

.document-view-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px 0 30px 15px; }
  @media (max-width: 1024px) {
    .document-view-right {
      padding: 30px 0; } }
  @media (max-width: 768px) {
    .document-view-right {
      padding: 10px 0; } }
  .document-view-right .document-right-container {
    position: sticky;
    top: 0;
    width: 100%;
    min-width: 100%;
    max-width: -webkit-fit-content !important;
    max-width: fit-content !important;
    max-width: -moz-fit-content !important; }

.document-view-block {
  margin: 20px 0; }
  .document-view-block .document-view-block-title {
    background: #333333;
    color: #ffffff;
    font-size: 15px;
    margin: 0;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0; }
  .document-view-block .document-view-block-content {
    background: #ffffff;
    padding: 15px;
    border-radius: 0 0 5px 5px;
    overflow: visible; }

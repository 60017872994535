/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.conversation-preview-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 15px;
  border-bottom: 2px solid #dbdbdb;
  font-size: 15px;
  cursor: pointer; }
  .conversation-preview-container.active {
    background-color: #F4F4F4;
    border-radius: 4px; }
    .conversation-preview-container.active .users-address-container .message-preview-grouping .message-preview-address, .conversation-preview-container.active .users-address-container .message-preview-grouping .message-preview-file {
      opacity: 1; }
  .conversation-preview-container .message-preview-title {
    font-size: 16px;
    font-size: 1rem;
    margin: 5px 0;
    font-weight: 700;
    white-space: normal;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; }
    .conversation-preview-container .message-preview-title strong {
      display: inline-block;
      vertical-align: middle; }
    .conversation-preview-container .message-preview-title em {
      display: inline-block;
      vertical-align: middle;
      color: #e91e63;
      margin: 0 0 0 20px;
      font-weight: normal; }
    .conversation-preview-container .message-preview-title .unread-message-counter {
      font-weight: normal;
      font-size: 12.8px;
      font-size: 0.8rem;
      margin-right: 5px;
      background-color: #DC3545;
      color: #ffffff;
      border-radius: 8px;
      padding: 1.6px 8px;
      padding: .1rem .5rem; }
  .conversation-preview-container.unread .message-preview-body {
    font-weight: bold; }
  .conversation-preview-container .users-address-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 5px; }
    .conversation-preview-container .users-address-container .message-preview-grouping {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      max-width: 50%; }
      .conversation-preview-container .users-address-container .message-preview-grouping .message-preview-address,
      .conversation-preview-container .users-address-container .message-preview-grouping .message-preview-file {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        opacity: 0.6; }
        .conversation-preview-container .users-address-container .message-preview-grouping .message-preview-address:before,
        .conversation-preview-container .users-address-container .message-preview-grouping .message-preview-file:before {
          margin-right: 5px;
          width: 13px;
          height: 13px; }
      .conversation-preview-container .users-address-container .message-preview-grouping .message-preview-file:before {
        margin-bottom: -1px; }
  .conversation-preview-container .message-preview {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: rgba(53, 53, 53, .8);
    width: 100%; }
    .conversation-preview-container .message-preview .message-preview-body {
      white-space: normal;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
      .conversation-preview-container .message-preview .message-preview-body .ban {
        margin-right: 5px; }
  .conversation-preview-container .message-preview-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 8px; }
  .conversation-preview-container .message-preview-created {
    display: block;
    font-size: 13px;
    color: rgba(53, 53, 53, .6); }
  .conversation-preview-container .message-preview-delete {
    display: block;
    font-size: 13px;
    background: #ffffff;
    color: rgba(53, 53, 53, .8);
    border: #dbdbdb 1px solid;
    border-radius: 3px;
    padding: 0 4px;
    height: auto; }
    .conversation-preview-container .message-preview-delete:hover {
      text-decoration: none; }
    .conversation-preview-container .message-preview-delete.icon:before {
      opacity: 0.6;
      width: 9px;
      height: 9px;
      margin-right: 4px; }
    .conversation-preview-container .message-preview-delete.icon-cross-dark:hover {
      color: #D4504B; }
      .conversation-preview-container .message-preview-delete.icon-cross-dark:hover:before {
        opacity: 1;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHZlcnNpb249IjEuMSIgZmlsbD0iI0RFNjU2MCI+PGcgaWQ9InN1cmZhY2UxIiBmaWxsPSIjREU2NTYwIj48cGF0aCBzdHlsZT0iICIgZD0iTSA3LjcxODc1IDYuMjgxMjUgTCA2LjI4MTI1IDcuNzE4NzUgTCAyMy41NjI1IDI1IEwgNi4yODEyNSA0Mi4yODEyNSBMIDcuNzE4NzUgNDMuNzE4NzUgTCAyNSAyNi40Mzc1IEwgNDIuMjgxMjUgNDMuNzE4NzUgTCA0My43MTg3NSA0Mi4yODEyNSBMIDI2LjQzNzUgMjUgTCA0My43MTg3NSA3LjcxODc1IEwgNDIuMjgxMjUgNi4yODEyNSBMIDI1IDIzLjU2MjUgWiAiIGZpbGw9IiNERTY1NjAiIHN0cm9rZT0iI0RFNjU2MCIgc3Ryb2tlLXdpZHRoPSI1Ii8+PC9nPjwvc3ZnPgo=); }

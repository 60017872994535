/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.chip {
  display: inline-block;
  padding: 0 12.5px;
  height: 25px;
  font-size: 13px;
  line-height: 25px;
  border-radius: 12.5px;
  background-color: #f1f1f1;
  margin-top: 5px;
  margin-right: 2px; }

button.chip-action {
  padding: 0;
  padding-left: 5px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 15px;
  cursor: pointer;
  border: none;
  height: 25px; }
  button.chip-action:hover {
    color: #000; }

.chip-container {
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px 0;
  background-color: #fff; }

.building-selection-section {
  background-color: #fff; }

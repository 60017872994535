/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.public-notice-item {
  position: relative;
  background-color: #ffffff;
  margin: 0 4.8px;
  margin: 0 0.3rem;
  border-radius: 0.3rem;
  height: 100%;
  max-height: 400px;
  grid-template-rows: 1fr 1fr 1fr auto 1fr; }

.public-notice-item--cover-image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
  margin-right: auto; }

.public-notice-item--cover-image {
  padding: 16px 16px 8px 16px;
  padding: 1rem 1rem .5rem 1rem;
  height: 150px;
  width: 350px;
  -o-object-fit: cover;
     object-fit: cover; }

.public-notice-item--action-buttons {
  padding: 16px 16px 0 16px;
  padding: 1rem 1rem 0 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.public-notice-item--title {
  padding: 8px 16px 4.8px 16px;
  padding: .5rem 1rem .3rem 1rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.public-notice-item--meta-data {
  padding: 0 16px 16px 16px;
  padding: 0 1rem 1rem 1rem;
  text-align: center;
  color: #939393;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 659px) {
    .public-notice-item--meta-data {
      font-size: 14px; } }

.public-notice-item--text {
  padding: 0 16px;
  padding: 0 1rem;
  text-align: center;
  height: 60px;
  overflow: hidden; }
  .public-notice-item--text ol {
    list-style-type: decimal;
    list-style-position: inside; }
  .public-notice-item--text ul {
    list-style-type: disc;
    list-style-position: inside; }

.public-notice-item--more-action {
  padding: 8px 16px 16px 16px;
  padding: .5rem 1rem 1rem 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center; }
  .public-notice-item--more-action .button {
    line-height: 1.5em; }

.alice-carousel__stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.alice-carousel__stage-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  height: auto; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.job-content .sidebar-job-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 10px; }
  .job-content .sidebar-job-details .sidebar-job-id {
    border: 1px solid black;
    border-radius: 8px;
    padding: 0 15px;
    font-size: 12px; }

.job-content .sidebar-job-description {
  position: relative;
  line-height: 1.45em;
  max-height: 67.2px;
  max-height: 4.2rem;
  overflow: hidden;
  font-size: 15px;
  margin-top: 8px; }

.job-content .preview-image-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  height: 100px;
  width: 100%;
  padding-top: 10px; }
  @media (max-width: 1024px) {
    .job-content .preview-image-row {
      height: 150px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  .job-content .preview-image-row .preview-image {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
    margin: auto; }
  .job-content .preview-image-row .no-images-message {
    grid-column: span 3; }

.job-content .google-maps-container {
  margin-top: 10px; }

.job-content .google-map {
  margin-bottom: 20px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
@media (max-width: 1024px) {
  .building-popular-documents-container {
    padding: 0; } }

@media (max-width: 659px) {
  .building-popular-documents-container {
    grid-column: 1 / 2;
    border-bottom: 1px solid #e2e2e2; } }

.building-popular-documents-container .work-documents-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 30px; }

.building-popular-documents-container .popular-documents-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;
  max-height: 360px;
  overflow: auto; }
  @media (max-width: 1024px) {
    .building-popular-documents-container .popular-documents-grid {
      grid-gap: 5px 5px; } }
  @media (max-width: 659px) {
    .building-popular-documents-container .popular-documents-grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: 5px 5px;
      padding-bottom: 20px;
      max-height: 360px; }
      .building-popular-documents-container .popular-documents-grid.inactive {
        display: none; } }
  .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid {
    grid-template-columns: 40px auto;
    margin: 0 0 12px; }
    .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid:nth-last-of-type(-n + 2) {
      margin: 0; }
    @media (max-width: 659px) {
      .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid {
        width: 100%;
        padding: 5px 7px; }
        .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid:nth-last-of-type(-n + 2) {
          margin-bottom: 12px; } }
    .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid .file-type-icon {
      justify-self: flex-start; }
    .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid .document-name-tag {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -ms-flex-wrap: initial;
          flex-wrap: initial;
      min-width: 100%; }
      .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid .document-name-tag .document-name {
        width: 100%;
        -ms-flex-item-align: start;
            align-self: flex-start; }
      .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid .document-name-tag .document-tag-border {
        margin: 5px 0 0;
        width: auto;
        border: none;
        background: #eaeaea;
        padding: 5px 10px;
        -ms-flex-item-align: start;
            align-self: flex-start; }
    .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign .single-document-grid .document-details {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: #F5F5F5; }
  .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }
  .building-popular-documents-container .popular-documents-grid.popular-documents-grid_redesign::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555; }
  .building-popular-documents-container .popular-documents-grid .single-document-grid {
    display: grid;
    background-color: #ffffff;
    padding: 15px 15px 15px 10px; }
    @media (max-width: 1024px) {
      .building-popular-documents-container .popular-documents-grid .single-document-grid {
        padding: 5px 5px 5px 0; } }
    .building-popular-documents-container .popular-documents-grid .single-document-grid.popular-doc-link:hover {
      text-decoration: none; }
    .building-popular-documents-container .popular-documents-grid .single-document-grid .file-type-icon {
      grid-row: 1 / 3;
      justify-self: center;
      height: 45px;
      width: 30px;
      font-size: 10px; }
      @media (max-width: 1259px) {
        .building-popular-documents-container .popular-documents-grid .single-document-grid .file-type-icon {
          height: 40px;
          width: 25px;
          font-size: 9px; } }
      @media (max-width: 1024px) {
        .building-popular-documents-container .popular-documents-grid .single-document-grid .file-type-icon {
          margin: 5px 5px 0 5px; } }
      @media (max-width: 659px) {
        .building-popular-documents-container .popular-documents-grid .single-document-grid .file-type-icon {
          height: 35px;
          width: 25px; } }
    .building-popular-documents-container .popular-documents-grid .single-document-grid .document-name-tag {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .building-popular-documents-container .popular-documents-grid .single-document-grid .document-name-tag .document-name {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: center;
            align-self: center;
        font-size: 14px;
        padding-right: 2px; }
        .building-popular-documents-container .popular-documents-grid .single-document-grid .document-name-tag .document-name .document-title {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
      .building-popular-documents-container .popular-documents-grid .single-document-grid .document-name-tag .document-tag-border {
        border: 1px solid #333333;
        border-radius: 3px;
        width: auto;
        padding: 5px 15px;
        height: 18px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: center;
            align-self: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        justify-self: flex-end;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        font-size: 11px;
        color: #333333;
        text-transform: uppercase;
        margin-top: 10px; }
        @media (max-width: 1024px) {
          .building-popular-documents-container .popular-documents-grid .single-document-grid .document-name-tag .document-tag-border {
            width: 75px;
            font-size: 10px;
            justify-self: flex-start; } }
        .building-popular-documents-container .popular-documents-grid .single-document-grid .document-name-tag .document-tag-border .document-tag-text {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
    .building-popular-documents-container .popular-documents-grid .single-document-grid .document-details {
      font-size: 13px;
      color: #939393;
      line-height: 1.2em;
      padding-top: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      @media (max-width: 1024px) {
        .building-popular-documents-container .popular-documents-grid .single-document-grid .document-details {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-item-align: center;
              align-self: center; } }

.building-popular-documents-container .mobile {
  text-align: center;
  margin-top: 20px;
  font-size: 13px; }

.building-popular-documents-container .not-mobile {
  display: none; }

.building-popular-documents-container .popular-buttons-container .button {
  margin-left: 10px; }

.building-popular-documents-container .popular-document-placeholder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ffffff;
  min-height: 200px; }
  @media (max-width: 1024px) {
    .building-popular-documents-container .popular-document-placeholder {
      min-height: 100px; } }

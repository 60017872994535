/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.meeting-section {
  margin: auto;
  max-width: 1400px;
  padding: 20px 30px; }
  @media (max-width: 659px) {
    .meeting-section {
      padding: 15px; } }

.meetings-state-switches {
  margin-bottom: 8px;
  margin-bottom: .5rem; }

.meeting-state__upcoming, .meeting-state__completed {
  background-color: transparent;
  border-color: transparent;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 150%;
  line-height: 1.2em;
  color: #B7B7B7;
  border-radius: .5rem;
  padding: 16px 11.2px;
  padding: 1rem .7rem; }
  .meeting-state__upcoming:hover, .meeting-state__completed:hover {
    background-color: #ddd;
    color: #363535; }
  .meeting-state__upcoming.active, .meeting-state__completed.active {
    cursor: default;
    color: #363535; }
    .meeting-state__upcoming.active:hover, .meeting-state__completed.active:hover {
      background-color: transparent; }
  @media (max-width: 1024px) {
    .meeting-state__upcoming, .meeting-state__completed {
      font-size: 18px; } }
  @media (max-width: 768px) {
    .meeting-state__upcoming, .meeting-state__completed {
      font-size: 16px; } }
  @media (max-width: 499px) {
    .meeting-state__upcoming, .meeting-state__completed {
      font-size: 18px; } }

.meeting-state__upcoming {
  padding-left: 0; }

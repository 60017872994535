/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.messages-container-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  background-color: #ffffff;
  padding: 0 40px; }
  @media (max-width: 1024px) {
    .messages-container-wrapper {
      padding: 0;
      display: inline-block;
      width: 100%; } }
  .messages-container-wrapper .loading-container {
    -webkit-box-flex: 0.75;
        -ms-flex: 0.75;
            flex: 0.75;
    height: 100%; }
  .messages-container-wrapper .messaging-middle-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0.4;
        -ms-flex: 0.4;
            flex: 0.4;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow-y: auto; }
    .messages-container-wrapper .messaging-middle-container .mobile-messaging-navbar {
      background: #F4F4F4;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 10px 10px; }
      @media (min-width: 1025px) {
        .messages-container-wrapper .messaging-middle-container .mobile-messaging-navbar {
          display: none;
          padding: 0px; } }
      .messages-container-wrapper .messaging-middle-container .mobile-messaging-navbar button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border: 0;
        background: transparent !important;
        padding: 0; }
      .messages-container-wrapper .messaging-middle-container .mobile-messaging-navbar .back-button:before {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
      .messages-container-wrapper .messaging-middle-container .mobile-messaging-navbar .show-document-button:after {
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
      .messages-container-wrapper .messaging-middle-container .mobile-messaging-navbar .show-document-button.inactive {
        display: none; }
    @media (max-width: 1024px) {
      .messages-container-wrapper .messaging-middle-container {
        height: 100%; }
        .messages-container-wrapper .messaging-middle-container.inactive {
          display: none; } }
    .messages-container-wrapper .messaging-middle-container .no-message-clicked {
      -ms-flex-item-align: center;
          align-self: center;
      margin: 30px auto; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
@media print {
  .messages-content .avatar-photo {
    width: 30px !important;
    height: 30px !important; }
  .messages-content .chatroom-info-header button,
  .messages-content .message-box-wrapper,
  .messages-content .messaging-middle-container .mobile-messaging-navbar,
  .messages-content .messaging-sidebar-container,
  .messages-content .footer {
    display: none !important; }
  .messages-content .messages-container-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .messages-content .messaging-middle-container {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
            order: 2; }
  .messages-content .right-sidebar-container {
    border-left: 0px !important;
    border-bottom: 2px solid #dbdbdb;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .messages-content .message-group-container .message-group-date-wrapper:before {
    border: none; }
  .messages-content .message-group-container .print-message-name {
    display: block;
    padding: 10px 20px;
    text-align: right; }
  .messages-content .message-group-container .message-item-meta-container .message-item-meta .message-item-actions {
    display: none; }
  .messages-content .message-group-container .message-group .message-item .message-body .message-container {
    border: 1px solid #dbdbdb; } }

.dropzone-modal .modal-content {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: hidden; }
  .dropzone-modal .modal-content .modal-cross-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
    .dropzone-modal .modal-content .modal-cross-close:before {
      height: 18px;
      width: 18px;
      background-color: #EAEDF0;
      border: 6px solid #EAEDF0;
      border-radius: 50%; }
  .dropzone-modal .modal-content .file-upload-container {
    padding: 0 15px 15px 15px; }
    .dropzone-modal .modal-content .file-upload-container p {
      font-size: 14px; }
    .dropzone-modal .modal-content .file-upload-container .title {
      font-size: 24px;
      font-weight: bold;
      text-transform: capitalize; }
    .dropzone-modal .modal-content .file-upload-container .component-background {
      padding: 10px;
      background-color: #f8fafb;
      margin-bottom: 15px;
      border-radius: 5px; }
      @media (max-width: 499px) {
        .dropzone-modal .modal-content .file-upload-container .component-background {
          width: 100%; } }
    .dropzone-modal .modal-content .file-upload-container .button-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin-top: 19px; }
      .dropzone-modal .modal-content .file-upload-container .button-container button {
        width: 165px;
        height: 42px; }
        @media (max-width: 499px) {
          .dropzone-modal .modal-content .file-upload-container .button-container button {
            width: 130px; } }
    .dropzone-modal .modal-content .file-upload-container .upload-file-modal .uploaded-grid {
      display: grid;
      grid-template-columns: 55px auto 30px;
      width: 100%; }
    .dropzone-modal .modal-content .file-upload-container .upload-file-modal .file-upload-bottom {
      grid-row: 2/3;
      grid-column: 2/4;
      border-top: 1px solid #dadada; }
      .dropzone-modal .modal-content .file-upload-container .upload-file-modal .file-upload-bottom .document-details {
        margin: 5px 0 0 2px; }
      .dropzone-modal .modal-content .file-upload-container .upload-file-modal .file-upload-bottom .suggest-file-name:hover {
        text-decoration: none; }
      .dropzone-modal .modal-content .file-upload-container .upload-file-modal .file-upload-bottom .suggest-file-name .suggest-link {
        color: #0081ff; }
        .dropzone-modal .modal-content .file-upload-container .upload-file-modal .file-upload-bottom .suggest-file-name .suggest-link:hover {
          text-decoration: underline;
          cursor: pointer; }
    .dropzone-modal .modal-content .file-upload-container .upload-file-modal .additional-fields {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      padding: 0 0 5px 8px; }
      .dropzone-modal .modal-content .file-upload-container .upload-file-modal .additional-fields .icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        font-size: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer; }
        .dropzone-modal .modal-content .file-upload-container .upload-file-modal .additional-fields .icon:before {
          width: 15px;
          height: 15px; }
    .dropzone-modal .modal-content .file-upload-container .upload-file-modal .no-sp-field {
      grid-column: 2 / 3; }
      .dropzone-modal .modal-content .file-upload-container .upload-file-modal .no-sp-field.inactive {
        display: none; }
    .dropzone-modal .modal-content .file-upload-container .file-inputs .hidden-field {
      display: none; }
    .dropzone-modal .modal-content .file-upload-container .additional-inputs,
    .dropzone-modal .modal-content .file-upload-container .global-inputs {
      grid-column: 2/4; }
      .dropzone-modal .modal-content .file-upload-container .additional-inputs .field,
      .dropzone-modal .modal-content .file-upload-container .global-inputs .field {
        display: inline-block; }
        .dropzone-modal .modal-content .file-upload-container .additional-inputs .field .field-title,
        .dropzone-modal .modal-content .file-upload-container .global-inputs .field .field-title {
          grid-column: 1 / 3; }
      .dropzone-modal .modal-content .file-upload-container .additional-inputs .hidden-field,
      .dropzone-modal .modal-content .file-upload-container .global-inputs .hidden-field {
        display: none; }
      .dropzone-modal .modal-content .file-upload-container .additional-inputs.group-checkboxes,
      .dropzone-modal .modal-content .file-upload-container .global-inputs.group-checkboxes {
        margin-top: 5px; }
        .dropzone-modal .modal-content .file-upload-container .additional-inputs.group-checkboxes .checkboxes-field.inactive .options .option label:before,
        .dropzone-modal .modal-content .file-upload-container .global-inputs.group-checkboxes .checkboxes-field.inactive .options .option label:before {
          background-color: #f2f2f2; }
      .dropzone-modal .modal-content .file-upload-container .additional-inputs .everyone-checkbox,
      .dropzone-modal .modal-content .file-upload-container .additional-inputs .tenant-checkbox,
      .dropzone-modal .modal-content .file-upload-container .global-inputs .everyone-checkbox,
      .dropzone-modal .modal-content .file-upload-container .global-inputs .tenant-checkbox {
        grid-column: 1 / 2; }
      .dropzone-modal .modal-content .file-upload-container .additional-inputs.add-to-inputs,
      .dropzone-modal .modal-content .file-upload-container .global-inputs.add-to-inputs {
        margin-top: 10px; }
    @media (max-width: 499px) {
      .dropzone-modal .modal-content .file-upload-container {
        width: 100%; } }
  .dropzone-modal .modal-content .global-fields .label {
    margin: 10px 0;
    cursor: pointer; }
  .dropzone-modal .modal-content .global-fields .icon-after:after {
    width: 9px;
    height: 9px;
    margin-left: 9px; }
  .dropzone-modal .modal-content .global-fields-text.active {
    color: #D4504B;
    font-weight: bold; }
  .dropzone-modal .modal-content .global-fields-text.inactive {
    display: none; }

.dropzone-modal .react-select__menu {
  z-index: 1001;
  max-height: 145px; }
  .dropzone-modal .react-select__menu .react-select__menu-list {
    max-height: 140px; }

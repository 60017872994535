/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.dropdown-container {
  background-color: white;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
          box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
  border-radius: 0 0 5px 5px;
  overflow: hidden; }
  .dropdown-container .content {
    padding: 8px 20px;
    font-size: 14px; }
  .dropdown-container .search-result:hover {
    background-color: #FBFBFB;
    color: #000000; }
  .dropdown-container .search-title {
    background-color: #FBFBFB;
    border: 1px solid #EDF2F5;
    border-right: none;
    border-left: none;
    font-weight: bold;
    vertical-align: bottom;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .dropdown-container .search-title:before {
      width: 18px;
      height: 18px;
      margin-right: 15px; }
  .dropdown-container .search-value {
    font-weight: bold; }
  .dropdown-container .search-result {
    cursor: pointer; }

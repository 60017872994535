/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.notification-settings-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  .notification-settings-container form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px; }
  .notification-settings-container .hidden {
    visibility: hidden !important; }
  .notification-settings-container .field-heading {
    border-width: 0; }
  .notification-settings-container .field-wrapper {
    margin-top: 20px; }
  .notification-settings-container .fields-snooze {
    width: 50%; }
  .notification-settings-container button[type='submit'] {
    margin-top: 10px;
    float: right; }
  .notification-settings-container .notification-settings-actions {
    overflow: hidden; }
  .notification-settings-container .notification-settings-snooze {
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1px solid #eee; }
    .notification-settings-container .notification-settings-snooze .snooze-fields {
      width: 30%; }
  .notification-settings-container .notification-settings-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr); }
  .notification-settings-container .notification-settings-header {
    padding: 5px 30px 20px; }
    .notification-settings-container .notification-settings-header .settings-header {
      font-weight: bold; }
      .notification-settings-container .notification-settings-header .settings-header.activity-setting {
        grid-column: 1 / span 4; }
      .notification-settings-container .notification-settings-header .settings-header.setting-toggle {
        grid-column: span 1;
        justify-self: center; }
      .notification-settings-container .notification-settings-header .settings-header.email-frequency {
        grid-column: 8 / span 5;
        justify-self: center; }
  .notification-settings-container .notification-settings-toggle .icon-after::after {
    width: 9px;
    height: 9px;
    margin-left: 9px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  .notification-settings-container .notification-settings-toggle .active.icon-after::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .notification-settings-container .notification-settings-item-container {
    max-width: 1460px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 15px 30px;
    margin-bottom: 10px; }
  .notification-settings-container .notification-settings-item .field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .notification-settings-container .notification-settings-item .field-heading {
    grid-column: 1 / span 4;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .notification-settings-container .notification-settings-item .field-heading .hint {
      font-size: 90%;
      color: #999; }
  .notification-settings-container .notification-settings-item .field-toggle {
    grid-column: span 1;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .notification-settings-container .notification-settings-item .select-field {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-column: 8 / span 5; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.vote-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  gap: .5rem; }

.vote-options input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0; }

.vote-options label {
  font-weight: 700;
  background-color: transparent;
  border: 1px solid #B7B7B7;
  color: #B7B7B7;
  border-radius: 3px;
  cursor: pointer;
  padding: .4em .8em;
  font-size: 0.95em; }
  @media (max-width: 499px) {
    .vote-options label {
      font-size: 0.85em;
      padding: .2em .4em; } }

.vote-options label:hover:hover {
  color: #ffffff;
  background-color: #4FCBB2;
  border-color: #4FCBB2; }

.vote-options input[type="radio"]:checked + label {
  color: #ffffff;
  background-color: #4FCBB2;
  border-color: #4FCBB2; }

.vote-options input[value='N'] + label:hover:hover {
  color: #ffffff;
  background-color: #DC3545;
  border-color: #DC3545; }

.vote-options input[type="radio"][value='N']:checked + label {
  color: #ffffff;
  background-color: #DC3545;
  border-color: #DC3545; }

.vote-options input[value='A'] + label:hover:hover {
  color: #ffffff;
  background-color: #B7B7B7;
  border-color: #B7B7B7; }

.vote-options input[type="radio"][value='A']:checked + label {
  color: #ffffff;
  background-color: #B7B7B7;
  border-color: #B7B7B7; }

.last-vote {
  padding-top: 16px;
  padding-top: 1rem;
  font-size: 80%; }
  .last-vote span[data-vote="Y"] {
    color: #4FCBB2; }
  .last-vote span[data-vote="A"] {
    color: #B7B7B7; }
  .last-vote span[data-vote="N"] {
    color: #DC3545; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.error-content {
  text-align: center;
  padding: 10px;
  line-height: normal; }
  .error-content .error-code {
    font-size: 1200%;
    font-weight: bold;
    color: #78e0cb; }
    @media (max-width: 767px) {
      .error-content .error-code {
        font-size: 600%; } }
  .error-content .error-message-primary {
    font-size: 280%; }
    @media (max-width: 767px) {
      .error-content .error-message-primary {
        font-size: 140%; } }
  .error-content .error-message-secondary {
    font-size: 130%; }
    @media (max-width: 767px) {
      .error-content .error-message-secondary {
        font-size: 100%; } }
  .error-content .action-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .error-content .action-buttons .button {
      margin: 20px 15px 0 15px;
      border-radius: 4px;
      font-weight: bold; }
    .error-content .action-buttons i.fa {
      font-size: 150%; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.right-sidebar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0.35;
      -ms-flex: 0.35;
          flex: 0.35;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #ffffff;
  overflow-y: auto;
  border-left: 2px solid #dbdbdb; }
  @media (max-width: 1024px) {
    .right-sidebar-container.inactive {
      display: none; }
    .right-sidebar-container.active {
      padding: 0; } }
  .right-sidebar-container .back-button {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #F4F4F4;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 10px 10px; }
    .right-sidebar-container .back-button:before {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
    @media (max-width: 1024px) {
      .right-sidebar-container .back-button.inactive {
        display: none; } }
    @media (min-width: 1025px) {
      .right-sidebar-container .back-button {
        display: none;
        padding: 0; } }
  .right-sidebar-container .right-sidebar-content.document-content {
    padding: 10px 20px;
    font-size: 15px;
    overflow-y: hidden; }
    .right-sidebar-container .right-sidebar-content.document-content h3 {
      margin: 10px 0;
      padding-bottom: 10px; }
    .right-sidebar-container .right-sidebar-content.document-content .sidebar-content {
      margin: 25px 0; }
    .right-sidebar-container .right-sidebar-content.document-content .sidebar-contacts strong {
      display: block;
      margin-bottom: 10px; }
    .right-sidebar-container .right-sidebar-content.document-content .sidebar-contacts .siderbar-individual-contact {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0; }
      .right-sidebar-container .right-sidebar-content.document-content .sidebar-contacts .siderbar-individual-contact > div {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        max-width: 60%; }
      .right-sidebar-container .right-sidebar-content.document-content .sidebar-contacts .siderbar-individual-contact .avatar {
        margin-right: 10px; }
      .right-sidebar-container .right-sidebar-content.document-content .sidebar-contacts .siderbar-individual-contact .sidebar-individual-contact-name {
        display: block;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .right-sidebar-container .right-sidebar-content.document-content .sidebar-text-item {
      -ms-flex-negative: 0;
          flex-shrink: 0;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      color: #ffffff;
      background-color: #333333;
      padding: 8px 10px;
      line-height: 1em;
      border-radius: 5px;
      margin-right: 5px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold; }
  .right-sidebar-container .right-sidebar-content.document-content:hover {
    overflow-y: auto; }
  .right-sidebar-container .sidebar-view-document {
    padding: 10px;
    width: 100%; }
    .right-sidebar-container .sidebar-view-document .sidebar-view-document-button {
      text-align: center;
      width: 100%; }

.chatroom-info-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .chatroom-info-header > h4 {
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .chatroom-info-header > button.icon {
    border: none;
    background-color: inherit; }

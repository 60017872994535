/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
@media (max-width: 1024px) {
  .whats-nearby-container {
    max-width: 100%;
    margin: 0; } }

@media (max-width: 659px) {
  .whats-nearby-container {
    border-bottom: 1px solid #E2E2E2; } }

.whats-nearby-container .mobile-view-header {
  margin: 0 0 30px 0; }

.whats-nearby-container .whats-nearby-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 10px;
  padding: 0 0 40px 0; }
  @media (max-width: 659px) {
    .whats-nearby-container .whats-nearby-grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5px 5px; }
      .whats-nearby-container .whats-nearby-grid-container.inactive {
        display: none; } }
  .whats-nearby-container .whats-nearby-grid-container .single-nearby-item {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    display: grid;
    grid-template-columns: 15% auto; }
    @media (max-width: 399px) {
      .whats-nearby-container .whats-nearby-grid-container .single-nearby-item {
        padding: 5px; } }
    @media (max-width: 1024px) {
      .whats-nearby-container .whats-nearby-grid-container .single-nearby-item {
        min-width: 140px; } }
    .whats-nearby-container .whats-nearby-grid-container .single-nearby-item .nearby-icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: 5px; }
      .whats-nearby-container .whats-nearby-grid-container .single-nearby-item .nearby-icon:before {
        height: 20px;
        width: 20px; }
    .whats-nearby-container .whats-nearby-grid-container .single-nearby-item .nearby-name-type-distance {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      font-size: 14px;
      min-width: 100%; }
      @media (max-width: 399px) {
        .whats-nearby-container .whats-nearby-grid-container .single-nearby-item .nearby-name-type-distance {
          font-size: 12px; } }
      .whats-nearby-container .whats-nearby-grid-container .single-nearby-item .nearby-name-type-distance .nearby-name {
        display: inline-block;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold; }
        @media (max-width: 1024px) {
          .whats-nearby-container .whats-nearby-grid-container .single-nearby-item .nearby-name-type-distance .nearby-name {
            display: inline-block;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; } }
      .whats-nearby-container .whats-nearby-grid-container .single-nearby-item .nearby-name-type-distance .nearby-type-distance {
        color: #939393; }

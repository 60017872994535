/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.message-group-container-scrollbox {
  overflow: auto;
  visibility: hidden;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.message-group-container-scrollbox:hover,
.message-group-container-scrollbox:active {
  visibility: visible; }

.message-group-container {
  visibility: visible; }
  .message-group-container .message-group-date-wrapper {
    text-align: center;
    margin: 20px 15px;
    position: relative; }
  .message-group-container .message-group-date {
    padding: 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative; }
    .message-group-container .message-group-date > span {
      background: #ffffff;
      padding: 0 20px;
      font-size: 12px;
      color: rgba(53, 53, 53, .3); }
  .message-group-container .message-group-date-wrapper:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 0.1px solid rgba(53, 53, 53, .1);
    border-radius: 1px;
    background: rgba(53, 53, 53, .1);
    width: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .message-group-container .message-history {
    text-align: center;
    color: rgba(53, 53, 53, .3);
    padding: 0 10px 15px 10px;
    font-size: 12px; }
  .message-group-container .print-message-name {
    display: none; }

.message-box-wrapper {
  position: relative;
  border: 1px solid #D8E5EE;
  border-radius: 5px;
  padding: 0 5px 5px 5px;
  margin: 0 10px 10px 10px; }
  .message-box-wrapper:focus-within {
    border: 1px solid #4FCBB2; }

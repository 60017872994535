/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.recent-work-modal-container {
  padding: 0 20px 20px 20px;
  max-height: 90vh;
  overflow-y: auto; }
  .recent-work-modal-container .recent-work-modal-title {
    margin: 0 0 20px 0; }
  .recent-work-modal-container .recent-work-list-container {
    max-height: 100vh; }
    .recent-work-modal-container .recent-work-list-container .work-history-content-grid {
      height: 20%;
      display: grid;
      grid-template-columns: 10% auto; }
      @media (max-width: 659px) {
        .recent-work-modal-container .recent-work-list-container .work-history-content-grid.inactive {
          display: none; } }
      .recent-work-modal-container .recent-work-list-container .work-history-content-grid .icon-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .recent-work-modal-container .recent-work-list-container .work-history-content-grid .icon-container .work-icon:before {
          height: 20px;
          width: 20px;
          vertical-align: middle; }
        .recent-work-modal-container .recent-work-list-container .work-history-content-grid .icon-container .work-green-line {
          height: 100%;
          background-color: #4FCBB2;
          width: 3px;
          margin: 5px 0; }
      .recent-work-modal-container .recent-work-list-container .work-history-content-grid .work-text {
        line-height: 1.4em;
        padding-bottom: 20px; }
        .recent-work-modal-container .recent-work-list-container .work-history-content-grid .work-text .job-container .job-date {
          color: #8c8c8c; }
          @media (max-width: 659px) {
            .recent-work-modal-container .recent-work-list-container .work-history-content-grid .work-text .job-container .job-date {
              font-size: 14px; } }
        .recent-work-modal-container .recent-work-list-container .work-history-content-grid .work-text .job-container .subtitle {
          cursor: pointer; }
      .recent-work-modal-container .recent-work-list-container .work-history-content-grid:last-of-type .icon-container .work-green-line {
        display: none; }
  .recent-work-modal-container .job-filter-inputs .job-name-filter input {
    padding: 10px; }
  .recent-work-modal-container .job-filter-inputs .job-status-filter select {
    padding: 10px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.building-noticeboard-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media (max-width: 1024px) {
    .building-noticeboard-container {
      padding: 0; } }
  @media (max-width: 659px) {
    .building-noticeboard-container {
      border-bottom: 1px solid #e2e2e2; } }
  .building-noticeboard-container .noticeboard-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 30px; }
    .building-noticeboard-container .noticeboard-header .noticeboard-tabs-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .building-noticeboard-container .noticeboard-header .noticeboard-tabs-container .mobile-view-header {
        cursor: pointer;
        padding: 10px;
        border-radius: 4px; }
        .building-noticeboard-container .noticeboard-header .noticeboard-tabs-container .mobile-view-header.active {
          background-color: #ddd; }
    .building-noticeboard-container .noticeboard-header .noticeboard-buttons-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .building-noticeboard-container .noticeboard-header .noticeboard-buttons-container .button:last-of-type {
        margin-left: 10px; }
  .building-noticeboard-container .add-notice-button {
    position: absolute;
    top: 0;
    left: 170px;
    border-radius: 50%;
    border: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 25px;
    height: 25px; }
    @media (max-width: 1024px) {
      .building-noticeboard-container .add-notice-button {
        left: 110px; } }
    .building-noticeboard-container .add-notice-button:before {
      font-size: 18px;
      position: absolute;
      content: '+';
      background-image: none !important;
      -webkit-transform: translateY(10%);
              transform: translateY(10%);
      top: 0; }
  .building-noticeboard-container .noticeboard-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px; }
    @media (max-width: 659px) {
      .building-noticeboard-container .noticeboard-grid {
        padding-bottom: 20px; } }

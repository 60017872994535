/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
#strata-integration-page-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 100%; }
  #strata-integration-page-container h3.account-settings-title {
    padding: 30px 0 20px 0; }
  #strata-integration-page-container #strata-integration-container {
    padding: 20px 0; }
    #strata-integration-page-container #strata-integration-container .connection-established-container, #strata-integration-page-container #strata-integration-container .no-connection-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .connection-established-image, #strata-integration-page-container #strata-integration-container .connection-established-container .no-connection-image, #strata-integration-page-container #strata-integration-container .no-connection-container .connection-established-image, #strata-integration-page-container #strata-integration-container .no-connection-container .no-connection-image {
        width: 120px; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .connection-established-title, #strata-integration-page-container #strata-integration-container .connection-established-container .no-connection-title, #strata-integration-page-container #strata-integration-container .connection-established-container .import-completed-title, #strata-integration-page-container #strata-integration-container .connection-established-container .import__title--stopped, #strata-integration-page-container #strata-integration-container .connection-established-container .import__title--pending, #strata-integration-page-container #strata-integration-container .connection-established-container .import__title--failed, #strata-integration-page-container #strata-integration-container .no-connection-container .connection-established-title, #strata-integration-page-container #strata-integration-container .no-connection-container .no-connection-title, #strata-integration-page-container #strata-integration-container .no-connection-container .import-completed-title, #strata-integration-page-container #strata-integration-container .no-connection-container .import__title--stopped, #strata-integration-page-container #strata-integration-container .no-connection-container .import__title--pending, #strata-integration-page-container #strata-integration-container .no-connection-container .import__title--failed {
        padding: 15px 0; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .import-completed-title, #strata-integration-page-container #strata-integration-container .no-connection-container .import-completed-title {
        color: #4FCBB2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .toggle-wizard-modal-btn, #strata-integration-page-container #strata-integration-container .connection-established-container .import-data-btn, #strata-integration-page-container #strata-integration-container .no-connection-container .toggle-wizard-modal-btn, #strata-integration-page-container #strata-integration-container .no-connection-container .import-data-btn {
        margin: 0 10px; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .importing-status-container, #strata-integration-page-container #strata-integration-container .no-connection-container .importing-status-container {
        width: 100%; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .actions-container, #strata-integration-page-container #strata-integration-container .no-connection-container .actions-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .button, #strata-integration-page-container #strata-integration-container .no-connection-container .button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .button__text, #strata-integration-page-container #strata-integration-container .no-connection-container .button__text {
        margin-left: 5px; }
      #strata-integration-page-container #strata-integration-container .connection-established-container .import-log__controls, #strata-integration-page-container #strata-integration-container .no-connection-container .import-log__controls {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 15px;
        grid-gap: 10px; }
  #strata-integration-page-container .loading {
    height: 100%; }
  #strata-integration-page-container .import-disclaimer {
    color: #d4504b; }

.wizard-modal-container {
  min-height: 10vh;
  max-height: 60vh; }

.import-status-container {
  width: 70%;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1339px) {
    .import-status-container {
      width: 100%; } }

.import-log-list {
  margin-top: 15px;
  padding: 1em 2em;
  border: 1px solid #D8E5EE;
  background: #eee;
  border-radius: 5px; }
  .import-log-list .import-log-entity {
    margin-bottom: 1.2em; }
    .import-log-list .import-log-entity .import-log-status {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .import-log-list .import-log-entity .import-log-status .import-log-status-indicator {
        margin-right: 15px; }
    .import-log-list .import-log-entity .import-log-status-details {
      background: #F4F4F4;
      padding: .8em 2.3em;
      margin-top: .5em; }
  .import-log-list .import-log-entity:last-of-type {
    margin-bottom: 0; }

.import-log-details-accordion-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.import-wizard-modal .modal-content {
  width: 700px !important;
  max-width: 700px !important;
  height: 750px; }

.action-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.navigation-container {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
  margin-right: 16px;
  margin-right: 1rem;
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0; }
  .navigation-container .navigation-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    padding: 20px 20px;
    text-decoration: none; }
    .navigation-container .navigation-item.active {
      font-weight: bold;
      background-color: #4FCBB2;
      color: #ffffff; }
    .navigation-container .navigation-item:hover {
      cursor: pointer;
      font-weight: bold; }
  .navigation-container .navigation-item:first-of-type {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px; }
  .navigation-container .navigation-item:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
  @media (max-width: 499px) {
    .navigation-container {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem; }
      .navigation-container .navigation-container {
        width: 100%; }
      .navigation-container .navigation-item:first-of-type {
        border-top-right-radius: unset;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; }
      .navigation-container .navigation-item:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: unset; } }

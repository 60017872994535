/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.building-recent-work-container {
  height: 100%; }
  @media (max-width: 1024px) {
    .building-recent-work-container {
      padding: 0 10px 0 0; } }
  @media (max-width: 768px) {
    .building-recent-work-container {
      padding: 0; } }
  @media (max-width: 659px) {
    .building-recent-work-container {
      padding: 0;
      grid-column: 2 / 3;
      border-bottom: 1px solid #E2E2E2; } }
  .building-recent-work-container .work-documents-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 30px; }
    .building-recent-work-container .work-documents-header .work-history-buttons-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .building-recent-work-container .work-documents-header .work-history-buttons-container .button:last-of-type {
        margin-left: 10px; }
  .building-recent-work-container .work-history-content-grid {
    display: grid;
    grid-template-columns: 10% auto;
    margin-bottom: 10px; }
    @media (max-width: 659px) {
      .building-recent-work-container .work-history-content-grid.inactive {
        display: none; } }
    .building-recent-work-container .work-history-content-grid .icon-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .building-recent-work-container .work-history-content-grid .icon-container .work-icon:before {
        height: 20px;
        width: 20px;
        vertical-align: middle; }
      .building-recent-work-container .work-history-content-grid .icon-container .work-green-line {
        height: 40px;
        background-color: #4FCBB2;
        width: 3px;
        margin: 5px 0; }
    .building-recent-work-container .work-history-content-grid .work-text {
      line-height: 1.4em;
      padding-bottom: 20px; }
      .building-recent-work-container .work-history-content-grid .work-text .job-container .job-date {
        color: #8C8C8C; }
        @media (max-width: 659px) {
          .building-recent-work-container .work-history-content-grid .work-text .job-container .job-date {
            font-size: 14px; } }
    .building-recent-work-container .work-history-content-grid:last-of-type .icon-container .work-green-line {
      display: none; }
  .building-recent-work-container .work-history-placeholder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #ffffff;
    min-height: 200px; }
    @media (max-width: 1024px) {
      .building-recent-work-container .work-history-placeholder {
        min-height: 100px; } }

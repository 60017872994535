/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.message-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 16px; }
  .message-item .avatar {
    padding: 0 10px; }
  .message-item:hover .message-item-meta-container > .message-item-meta > .message-item-actions {
    visibility: visible; }
  .message-item .message-body {
    min-width: 50px;
    max-width: 70%; }
    @media (max-width: 339px) {
      .message-item .message-body {
        max-width: 200px; } }
    @media (max-width: 399px) {
      .message-item .message-body {
        max-width: 250px; } }
    .message-item .message-body .message-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      border-radius: 7px;
      padding: 9px 17px;
      background-color: #EEE;
      font-size: 15px;
      line-height: 1.55em; }
      .message-item .message-body .message-container .mention {
        color: #0d47a1; }
      .message-item .message-body .message-container .multiple-images-case {
        margin-bottom: 5px; }
      .message-item .message-body .message-container .file-download {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .message-item .message-body .message-container .file-download img {
          height: 50px;
          width: 60px; }
        .message-item .message-body .message-container .file-download .file-type-icon {
          margin-right: 3px; }
      .message-item .message-body .message-container .container {
        height: -webkit-max-content !important;
        height: -moz-max-content !important;
        height: max-content !important; }
  .message-item h6 {
    color: #979797;
    padding-left: 15px;
    margin: 0 0 5px;
    font-size: 15px; }
  .message-item.current-user {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    margin-right: 10px; }
    .message-item.current-user .avatar {
      padding-top: 0; }
    .message-item.current-user h6 {
      display: none; }
    .message-item.current-user .message-body .message-container {
      background-color: #4FCBB2;
      cursor: pointer; }
      .message-item.current-user .message-body .message-container .file-download a {
        color: #ffffff;
        padding-left: 5px; }
      .message-item.current-user .message-body .message-container .paragraph-message {
        color: #ffffff; }
  .message-item.group-same-owner {
    margin-top: -10px; }
    .message-item.group-same-owner .avatar {
      visibility: hidden; }
    .message-item.group-same-owner h6 {
      display: none; }
  .message-item .message-item-meta-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .message-item .message-item-meta {
    font-size: 12px;
    line-height: 12px; }
  .message-item .message-item-date {
    margin: 0 10px;
    color: rgba(53, 53, 53, .3);
    font-size: 12px;
    line-height: 12px; }
  .message-item .message-item-actions {
    float: right;
    padding: 5px 10px 0;
    visibility: hidden; }
    .message-item .message-item-actions .message-item-delete {
      font-size: 15.5px;
      margin-left: 10px; }
    .message-item .message-item-actions .message-item-delete, .message-item .message-item-actions .message-item-edit {
      cursor: pointer; }
      .message-item .message-item-actions .message-item-delete:hover, .message-item .message-item-actions .message-item-edit:hover {
        color: #D4504B; }
    .message-item .message-item-actions:hover {
      visibility: visible; }

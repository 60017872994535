/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.profile-settings-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 20px; }
  .profile-settings-container .user-profile {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    @media (max-width: 499px) {
      .profile-settings-container .user-profile {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .profile-settings-container .user-profile .update-profile-button {
      float: right;
      margin-top: 15px; }
    .profile-settings-container .user-profile .profile-avatar-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-top: 30px;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
      .profile-settings-container .user-profile .profile-avatar-container .avatar .avatar-photo {
        width: 200px;
        height: 200px; }
      .profile-settings-container .user-profile .profile-avatar-container .profile-avatar-name {
        margin: 15px 0 10px 0;
        text-align: center; }
      .profile-settings-container .user-profile .profile-avatar-container .profile-avatar-role {
        color: #4A90E2; }
    .profile-settings-container .user-profile .new-ui-checkbox {
      margin-top: 8px;
      margin-top: 0.5rem; }
  .profile-settings-container .user-password {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    display: grid;
    grid-column-gap: 10px; }
    .profile-settings-container .user-password .update-profile-button {
      float: right;
      margin-top: 15px; }
    .profile-settings-container .user-password .profile-avatar-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-top: 30px; }
      .profile-settings-container .user-password .profile-avatar-container .avatar .avatar-photo {
        width: 200px;
        height: 200px; }
      .profile-settings-container .user-password .profile-avatar-container .profile-avatar-name {
        margin: 15px 0 10px 0;
        text-align: center; }
      .profile-settings-container .user-password .profile-avatar-container .profile-avatar-role {
        color: #4A90E2; }
  .profile-settings-container .avatar-container {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto; }
    .profile-settings-container .avatar-container .outer {
      width: 100% !important;
      height: 100% !important;
      max-width: 200px !important;
      max-height: 200px !important;
      margin: auto;
      border-radius: 100%;
      position: relative; }
    .profile-settings-container .avatar-container button.inner {
      background-color: transparent;
      border: 0.5px solid #AAAAAA;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      position: absolute;
      bottom: 0;
      right: 0; }
    .profile-settings-container .avatar-container .avatar-photo {
      margin: 0; }
    .profile-settings-container .avatar-container .icon:before {
      width: 20px;
      height: 20px; }

/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.otp-settings-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  .otp-settings-container .otp-settings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px; }
    .otp-settings-container .otp-settings .otp-settings-form {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
      .otp-settings-container .otp-settings .otp-settings-form .otp-settings-field-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-radius: 5px;
        border: 1px solid #eee;
        padding: 15px 30px;
        margin-bottom: 10px; }
        .otp-settings-container .otp-settings .otp-settings-form .otp-settings-field-container .field-heading {
          border-bottom: 0; }
          .otp-settings-container .otp-settings .otp-settings-form .otp-settings-field-container .field-heading .hint {
            font-size: 90%;
            color: #999; }
        .otp-settings-container .otp-settings .otp-settings-form .otp-settings-field-container .field-toggle {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          -ms-flex-item-align: center;
              align-self: center; }
      .otp-settings-container .otp-settings .otp-settings-form .save-otp-settings-button {
        margin-top: 10px;
        float: right; }

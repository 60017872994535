/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
.meetings__page {
  padding: 0 32px;
  padding: 0 2rem; }

.meetings__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  gap: 1.5rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media (max-width: 768px) {
    .meetings__wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

.meetings__container--left {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
  border-right: 1.5px solid #E0E0E0; }
  @media (max-width: 768px) {
    .meetings__container--left {
      border-right: none;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; } }

.meetings__container--right {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2; }

.meeting-details {
  padding-bottom: 16px;
  padding-bottom: 1rem; }

.building-details {
  padding-top: 16px;
  padding-top: 1rem;
  padding-bottom: 16px;
  padding-bottom: 1rem;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0; }

.meeting-detail, .building-detail {
  margin-bottom: 8px;
  margin-bottom: .5rem;
  min-width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .meeting-detail span, .building-detail span {
    margin-left: 8px;
    margin-left: .5rem;
    min-width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
